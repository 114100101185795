/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { SWRConfig, useGetRequest } from "./useGetRequest";
import { ResponseError } from "../types";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { SWRMutationConfig, useMutationRequest } from "./useMutationRequest";

/*
 *  this file is generated by @openapi-integration/swr-request-generator.
 *  please do not modify it manually.
 */

export const useApplicationsAddRequest = (
  mutationConfig?: SWRMutationConfig<undefined, AxiosResponse<undefined>, ResponseError>,
  axiosConfig?: AxiosRequestConfig,
) =>
  useMutationRequest<undefined, AxiosResponse<undefined>, ResponseError>({
    url: `/v1/authed/applications`,
    method: "post",
    headers: {},
    mutationConfig,
    axiosConfig,
  });

export const useApplicationsDeleteRequest = (
  {
    applicationId,
  }: {
    applicationId: number;
  },
  mutationConfig?: SWRMutationConfig<undefined, AxiosResponse<undefined>, ResponseError>,
  axiosConfig?: AxiosRequestConfig,
) =>
  useMutationRequest<undefined, AxiosResponse<undefined>, ResponseError>({
    url: `/v1/authed/applications/${applicationId}`,
    method: "delete",
    headers: {},
    mutationConfig,
    axiosConfig,
  });

export const useApplicationsListRequest = (
  SWRConfig?: SWRConfig<JobApplicationResults, ResponseError>,
  axiosConfig?: AxiosRequestConfig,
) =>
  useGetRequest<JobApplicationResults, ResponseError>(
    {
      url: `/v1/authed/applications`,
      method: "get",
      headers: {},
      ...axiosConfig,
    },
    SWRConfig,
  );

export const useApplicationsUpdateRequest = (
  {
    applicationId,
  }: {
    applicationId: number;
  },
  mutationConfig?: SWRMutationConfig<undefined, AxiosResponse<undefined>, ResponseError>,
  axiosConfig?: AxiosRequestConfig,
) =>
  useMutationRequest<undefined, AxiosResponse<undefined>, ResponseError>({
    url: `/v1/authed/applications/${applicationId}`,
    method: "put",
    headers: {},
    mutationConfig,
    axiosConfig,
  });

export const useApprenticeCreateRequest = (
  mutationConfig?: SWRMutationConfig<ApprenticeCreateRequest, AxiosResponse<undefined>, ResponseError>,
  axiosConfig?: AxiosRequestConfig,
) =>
  useMutationRequest<ApprenticeCreateRequest, AxiosResponse<undefined>, ResponseError>({
    url: `/v1/apprentice`,
    method: "post",
    headers: { "Content-Type": "application/json" },
    mutationConfig,
    axiosConfig,
  });

export const useApprenticesListRequest = (
  {
    term,
  }: {
    term?: string;
  },
  SWRConfig?: SWRConfig<ApprenticeResults, ResponseError>,
  axiosConfig?: AxiosRequestConfig,
) =>
  useGetRequest<ApprenticeResults, ResponseError>(
    {
      url: `/v1/apprentices`,
      method: "get",
      headers: {},
      params: {
        term,
      },
      ...axiosConfig,
    },
    SWRConfig,
  );

export const useArticleCategoriesGetOneRequest = (
  {
    type,
    slug,
  }: {
    slug: string;
    type: string;
  },
  SWRConfig?: SWRConfig<ArticleCategory, ResponseError>,
  axiosConfig?: AxiosRequestConfig,
) =>
  useGetRequest<ArticleCategory, ResponseError>(
    {
      url: `/v1/categories/${type}/${slug}`,
      method: "get",
      headers: {},
      ...axiosConfig,
    },
    SWRConfig,
  );

export const useArticleCategoriesListRequest = (
  {
    type,
  }: {
    type: string;
  },
  SWRConfig?: SWRConfig<ArticleCategory, ResponseError>,
  axiosConfig?: AxiosRequestConfig,
) =>
  useGetRequest<ArticleCategory, ResponseError>(
    {
      url: `/v1/categories/${type}`,
      method: "get",
      headers: {},
      ...axiosConfig,
    },
    SWRConfig,
  );

export const useArticlesGetRequest = (
  {
    articleId,
  }: {
    articleId: number;
  },
  SWRConfig?: SWRConfig<Article, ResponseError>,
  axiosConfig?: AxiosRequestConfig,
) =>
  useGetRequest<Article, ResponseError>(
    {
      url: `/v1/articles/${articleId}`,
      method: "get",
      headers: {},
      ...axiosConfig,
    },
    SWRConfig,
  );

export const useArticlesListRequest = (
  {
    article_type,
    offset,
    limit,
    category_id,
    query,
  }: {
    article_type: ArticleType;
    category_id?: number;
    limit?: number;
    offset?: number;
    query?: string;
  },
  SWRConfig?: SWRConfig<ArticleResults, ResponseError>,
  axiosConfig?: AxiosRequestConfig,
) =>
  useGetRequest<ArticleResults, ResponseError>(
    {
      url: `/v1/articles`,
      method: "get",
      headers: {},
      params: {
        article_type,
        offset,
        limit,
        category_id,
        query,
      },
      ...axiosConfig,
    },
    SWRConfig,
  );

export const useCareerZonesGetRequest = (
  {
    careerZoneId,
  }: {
    careerZoneId: number;
  },
  SWRConfig?: SWRConfig<CareerZone, ResponseError>,
  axiosConfig?: AxiosRequestConfig,
) =>
  useGetRequest<CareerZone, ResponseError>(
    {
      url: `/v1/career_zones/${careerZoneId}`,
      method: "get",
      headers: {},
      ...axiosConfig,
    },
    SWRConfig,
  );

export const useCareerZonesListRequest = (
  SWRConfig?: SWRConfig<CareerZoneResults, ResponseError>,
  axiosConfig?: AxiosRequestConfig,
) =>
  useGetRequest<CareerZoneResults, ResponseError>(
    {
      url: `/v1/career_zones`,
      method: "get",
      headers: {},
      ...axiosConfig,
    },
    SWRConfig,
  );

export const useContactRequest = (
  mutationConfig?: SWRMutationConfig<undefined, AxiosResponse<undefined>, ResponseError>,
  axiosConfig?: AxiosRequestConfig,
) =>
  useMutationRequest<undefined, AxiosResponse<undefined>, ResponseError>({
    url: `/v1/contact`,
    method: "post",
    headers: {},
    mutationConfig,
    axiosConfig,
  });

export const useCoursesAddRequest = (
  mutationConfig?: SWRMutationConfig<undefined, AxiosResponse<undefined>, ResponseError>,
  axiosConfig?: AxiosRequestConfig,
) =>
  useMutationRequest<undefined, AxiosResponse<undefined>, ResponseError>({
    url: `/v1/authed/courses`,
    method: "post",
    headers: {},
    mutationConfig,
    axiosConfig,
  });

export const useCoursesUpdateRequest = (
  {
    courseId,
  }: {
    courseId: number;
  },
  mutationConfig?: SWRMutationConfig<undefined, AxiosResponse<undefined>, ResponseError>,
  axiosConfig?: AxiosRequestConfig,
) =>
  useMutationRequest<undefined, AxiosResponse<undefined>, ResponseError>({
    url: `/v1/authed/courses/${courseId}`,
    method: "put",
    headers: {},
    mutationConfig,
    axiosConfig,
  });

export const useEmployerCreateRequest = (
  mutationConfig?: SWRMutationConfig<EmployerCreateRequest, AxiosResponse<undefined>, ResponseError>,
  axiosConfig?: AxiosRequestConfig,
) =>
  useMutationRequest<EmployerCreateRequest, AxiosResponse<undefined>, ResponseError>({
    url: `/v1/employer`,
    method: "post",
    headers: { "Content-Type": "application/json" },
    mutationConfig,
    axiosConfig,
  });

export const useEmployersGetMyRequest = (
  SWRConfig?: SWRConfig<Organisation, ResponseError>,
  axiosConfig?: AxiosRequestConfig,
) =>
  useGetRequest<Organisation, ResponseError>(
    {
      url: `/v1/authed/employers`,
      method: "get",
      headers: {},
      ...axiosConfig,
    },
    SWRConfig,
  );

export const useEmployersGetOneRequest = (
  {
    employerId,
  }: {
    employerId: number;
  },
  SWRConfig?: SWRConfig<Organisation, ResponseError>,
  axiosConfig?: AxiosRequestConfig,
) =>
  useGetRequest<Organisation, ResponseError>(
    {
      url: `/v1/employers/${employerId}`,
      method: "get",
      headers: {},
      ...axiosConfig,
    },
    SWRConfig,
  );

export const useEmployersListRequest = (
  {
    career_zone_id,
    term,
  }: {
    career_zone_id?: number;
    term?: string;
  },
  SWRConfig?: SWRConfig<EmployerResults, ResponseError>,
  axiosConfig?: AxiosRequestConfig,
) =>
  useGetRequest<EmployerResults, ResponseError>(
    {
      url: `/v1/employers`,
      method: "get",
      headers: {},
      params: {
        career_zone_id,
        term,
      },
      ...axiosConfig,
    },
    SWRConfig,
  );

export const useFavouritesAddRequest = (
  {
    type,
    entityId,
  }: {
    entityId: number;
    type: string;
  },
  mutationConfig?: SWRMutationConfig<undefined, AxiosResponse<undefined>, ResponseError>,
  axiosConfig?: AxiosRequestConfig,
) =>
  useMutationRequest<undefined, AxiosResponse<undefined>, ResponseError>({
    url: `/v1/authed/favourites/${type}/${entityId}`,
    method: "post",
    headers: {},
    mutationConfig,
    axiosConfig,
  });

export const useForumsAddTopicRequest = (
  {
    subCatId,
  }: {
    subCatId: number;
  },
  mutationConfig?: SWRMutationConfig<ForumsAddTopicRequest, AxiosResponse<undefined>, ResponseError>,
  axiosConfig?: AxiosRequestConfig,
) =>
  useMutationRequest<ForumsAddTopicRequest, AxiosResponse<undefined>, ResponseError>({
    url: `/v1/authed/forums/topic/add/${subCatId}`,
    method: "post",
    headers: { "Content-Type": "application/json" },
    mutationConfig,
    axiosConfig,
  });

export const useForumsCommentAddRequest = (
  {
    topicId,
  }: {
    topicId: number;
  },
  mutationConfig?: SWRMutationConfig<ForumsCommentAddRequest, AxiosResponse<undefined>, ResponseError>,
  axiosConfig?: AxiosRequestConfig,
) =>
  useMutationRequest<ForumsCommentAddRequest, AxiosResponse<undefined>, ResponseError>({
    url: `/v1/authed/forums/topic/${topicId}/comment`,
    method: "post",
    headers: { "Content-Type": "application/json" },
    mutationConfig,
    axiosConfig,
  });

export const useForumsCommentRepliesRequest = (
  {
    topicId,
    commentId,
    sort,
  }: {
    commentId: number;
    sort?: string;
    topicId: number;
  },
  SWRConfig?: SWRConfig<ForumTopicComment[], ResponseError>,
  axiosConfig?: AxiosRequestConfig,
) =>
  useGetRequest<ForumTopicComment[], ResponseError>(
    {
      url: `/v1/forums/topic/${topicId}/comment/${commentId}`,
      method: "get",
      headers: {},
      params: {
        sort,
      },
      ...axiosConfig,
    },
    SWRConfig,
  );

export const useForumsCommentReportRequest = (
  {
    commentId,
  }: {
    commentId: number;
  },
  mutationConfig?: SWRMutationConfig<ForumsCommentReportRequest, AxiosResponse<{ success?: boolean }>, ResponseError>,
  axiosConfig?: AxiosRequestConfig,
) =>
  useMutationRequest<ForumsCommentReportRequest, AxiosResponse<{ success?: boolean }>, ResponseError>({
    url: `/v1/authed/forums/comment/${commentId}/report`,
    method: "post",
    headers: { "Content-Type": "application/json" },
    mutationConfig,
    axiosConfig,
  });

export const useForumsDeleteTopicRequest = (
  {
    topicId,
  }: {
    topicId: number;
  },
  mutationConfig?: SWRMutationConfig<undefined, AxiosResponse<{ success?: boolean }>, ResponseError>,
  axiosConfig?: AxiosRequestConfig,
) =>
  useMutationRequest<undefined, AxiosResponse<{ success?: boolean }>, ResponseError>({
    url: `/v1/authed/forums/topic/${topicId}`,
    method: "delete",
    headers: {},
    mutationConfig,
    axiosConfig,
  });

export const useForumsDeleteTopicCommentRequest = (
  {
    topicId,
    commentId,
  }: {
    commentId: number;
    topicId: number;
  },
  mutationConfig?: SWRMutationConfig<undefined, AxiosResponse<{ success?: boolean }>, ResponseError>,
  axiosConfig?: AxiosRequestConfig,
) =>
  useMutationRequest<undefined, AxiosResponse<{ success?: boolean }>, ResponseError>({
    url: `/v1/authed/forums/topic/${topicId}/comment/${commentId}`,
    method: "delete",
    headers: {},
    mutationConfig,
    axiosConfig,
  });

export const useForumsEditCommentRequest = (
  {
    topicId,
    commentId,
  }: {
    commentId: number;
    topicId: number;
  },
  mutationConfig?: SWRMutationConfig<ForumsEditCommentRequest, AxiosResponse<undefined>, ResponseError>,
  axiosConfig?: AxiosRequestConfig,
) =>
  useMutationRequest<ForumsEditCommentRequest, AxiosResponse<undefined>, ResponseError>({
    url: `/v1/authed/forums/topic/${topicId}/comment/${commentId}`,
    method: "put",
    headers: { "Content-Type": "application/json" },
    mutationConfig,
    axiosConfig,
  });

export const useForumsEditTopicRequest = (
  {
    subCatId,
    topicId,
  }: {
    subCatId: number;
    topicId: number;
  },
  mutationConfig?: SWRMutationConfig<ForumsEditTopicRequest, AxiosResponse<undefined>, ResponseError>,
  axiosConfig?: AxiosRequestConfig,
) =>
  useMutationRequest<ForumsEditTopicRequest, AxiosResponse<undefined>, ResponseError>({
    url: `/v1/authed/forums/topic/${subCatId}/edit/${topicId}`,
    method: "put",
    headers: { "Content-Type": "application/json" },
    mutationConfig,
    axiosConfig,
  });

export const useForumsLikeCommentRequest = (
  {
    commentId,
  }: {
    commentId: number;
  },
  mutationConfig?: SWRMutationConfig<undefined, AxiosResponse<undefined>, ResponseError>,
  axiosConfig?: AxiosRequestConfig,
) =>
  useMutationRequest<undefined, AxiosResponse<undefined>, ResponseError>({
    url: `/v1/authed/forums/comment/${commentId}/like`,
    method: "post",
    headers: {},
    mutationConfig,
    axiosConfig,
  });

export const useForumsLikeTopicRequest = (
  {
    topicId,
  }: {
    topicId: number;
  },
  mutationConfig?: SWRMutationConfig<undefined, AxiosResponse<undefined>, ResponseError>,
  axiosConfig?: AxiosRequestConfig,
) =>
  useMutationRequest<undefined, AxiosResponse<undefined>, ResponseError>({
    url: `/v1/authed/forums/topic/${topicId}/like`,
    method: "post",
    headers: {},
    mutationConfig,
    axiosConfig,
  });

export const useForumsListRequest = (
  {
    forumId,
    offset,
    limit,
    regionId,
    sectorId,
    employerId,
  }: {
    employerId?: number;
    forumId?: number;
    limit?: number;
    offset?: number;
    regionId?: number;
    sectorId?: number;
  },
  SWRConfig?: SWRConfig<ForumList, ResponseError>,
  axiosConfig?: AxiosRequestConfig,
) =>
  useGetRequest<ForumList, ResponseError>(
    {
      url: `/v1/forums/${forumId}`,
      method: "get",
      headers: {},
      params: {
        offset,
        limit,
        regionId,
        sectorId,
        employerId,
      },
      ...axiosConfig,
    },
    SWRConfig,
  );

export const useForumsSavedTopicsRequest = (
  SWRConfig?: SWRConfig<ForumTopic[], ResponseError>,
  axiosConfig?: AxiosRequestConfig,
) =>
  useGetRequest<ForumTopic[], ResponseError>(
    {
      url: `/v1/authed/forums/saved`,
      method: "get",
      headers: {},
      ...axiosConfig,
    },
    SWRConfig,
  );

export const useForumsSubscribeRequest = (
  {
    type,
    entityId,
  }: {
    entityId: number;
    type: string;
  },
  mutationConfig?: SWRMutationConfig<ForumsSubscribeRequest, AxiosResponse<undefined>, ResponseError>,
  axiosConfig?: AxiosRequestConfig,
) =>
  useMutationRequest<ForumsSubscribeRequest, AxiosResponse<undefined>, ResponseError>({
    url: `/v1/authed/forums/subscribe/${type}/${entityId}`,
    method: "post",
    headers: {},
    mutationConfig,
    axiosConfig,
  });

export const useForumsSubscribeListRequest = (
  SWRConfig?: SWRConfig<ForumSubCategory[], ResponseError>,
  axiosConfig?: AxiosRequestConfig,
) =>
  useGetRequest<ForumSubCategory[], ResponseError>(
    {
      url: `/v1/authed/forums/subscribe`,
      method: "get",
      headers: {},
      ...axiosConfig,
    },
    SWRConfig,
  );

export const useForumsTagsRequest = (
  SWRConfig?: SWRConfig<ForumTag[], ResponseError>,
  axiosConfig?: AxiosRequestConfig,
) =>
  useGetRequest<ForumTag[], ResponseError>(
    {
      url: `/v1/forums/tags`,
      method: "get",
      headers: {},
      ...axiosConfig,
    },
    SWRConfig,
  );

export const useForumsTopicRequest = (
  {
    topicId,
    sort,
  }: {
    sort?: string;
    topicId: number;
  },
  SWRConfig?: SWRConfig<ForumTopic, ResponseError>,
  axiosConfig?: AxiosRequestConfig,
) =>
  useGetRequest<ForumTopic, ResponseError>(
    {
      url: `/v1/forums/topic/${topicId}`,
      method: "get",
      headers: {},
      params: {
        sort,
      },
      ...axiosConfig,
    },
    SWRConfig,
  );

export const useForumsTopicsListRequest = (
  {
    forumId,
    offset,
    limit,
    sort,
    query,
    tag,
  }: {
    forumId: number;
    limit?: number;
    offset?: number;
    query?: string;
    sort?: string;
    tag?: string;
  },
  SWRConfig?: SWRConfig<ForumPostTopic[], ResponseError>,
  axiosConfig?: AxiosRequestConfig,
) =>
  useGetRequest<ForumPostTopic[], ResponseError>(
    {
      url: `/v1/forums/${forumId}/topics`,
      method: "get",
      headers: {},
      params: {
        offset,
        limit,
        sort,
        query,
        tag,
      },
      ...axiosConfig,
    },
    SWRConfig,
  );

export const useJobsAddRequest = (
  mutationConfig?: SWRMutationConfig<undefined, AxiosResponse<undefined>, ResponseError>,
  axiosConfig?: AxiosRequestConfig,
) =>
  useMutationRequest<undefined, AxiosResponse<undefined>, ResponseError>({
    url: `/v1/authed/jobs`,
    method: "post",
    headers: {},
    mutationConfig,
    axiosConfig,
  });

export const useJobsApplyRequest = (
  {
    jobId,
  }: {
    jobId: number;
  },
  SWRConfig?: SWRConfig<undefined, ResponseError>,
  axiosConfig?: AxiosRequestConfig,
) =>
  useGetRequest<undefined, ResponseError>(
    {
      url: `/v1/jobs/${jobId}/apply`,
      method: "get",
      headers: {},
      ...axiosConfig,
    },
    SWRConfig,
  );

export const useJobsDeleteRequest = (
  {
    jobId,
  }: {
    jobId: number;
  },
  mutationConfig?: SWRMutationConfig<undefined, AxiosResponse<undefined>, ResponseError>,
  axiosConfig?: AxiosRequestConfig,
) =>
  useMutationRequest<undefined, AxiosResponse<undefined>, ResponseError>({
    url: `/v1/authed/jobs/${jobId}`,
    method: "delete",
    headers: {},
    mutationConfig,
    axiosConfig,
  });

export const useJobsGetRequest = (
  {
    jobId,
  }: {
    jobId: number;
  },
  SWRConfig?: SWRConfig<Job, ResponseError>,
  axiosConfig?: AxiosRequestConfig,
) =>
  useGetRequest<Job, ResponseError>(
    {
      url: `/v1/jobs/${jobId}`,
      method: "get",
      headers: {},
      ...axiosConfig,
    },
    SWRConfig,
  );

export const useJobsGetSearchMetadataRequest = (
  SWRConfig?: SWRConfig<JobMetadataResults, ResponseError>,
  axiosConfig?: AxiosRequestConfig,
) =>
  useGetRequest<JobMetadataResults, ResponseError>(
    {
      url: `/v1/metadata/jobs_search`,
      method: "get",
      headers: {},
      ...axiosConfig,
    },
    SWRConfig,
  );

export const useJobsGetUnapprovedRequest = (
  {
    jobId,
  }: {
    jobId: number;
  },
  SWRConfig?: SWRConfig<Job, ResponseError>,
  axiosConfig?: AxiosRequestConfig,
) =>
  useGetRequest<Job, ResponseError>(
    {
      url: `/v1/authed/jobs/${jobId}`,
      method: "get",
      headers: {},
      ...axiosConfig,
    },
    SWRConfig,
  );

export const useJobsListRequest = (
  {
    offset,
    limit,
    career_zone_ids,
    job_type,
    featured,
    employer_id,
    location_id,
    query,
  }: {
    career_zone_ids?: number[];
    employer_id?: number;
    featured?: boolean;
    job_type?: JobType;
    limit?: number;
    location_id?: number;
    offset?: number;
    query?: string;
  },
  SWRConfig?: SWRConfig<JobResults, ResponseError>,
  axiosConfig?: AxiosRequestConfig,
) =>
  useGetRequest<JobResults, ResponseError>(
    {
      url: `/v1/jobs`,
      method: "get",
      headers: {},
      params: {
        offset,
        limit,
        career_zone_ids,
        job_type,
        featured,
        employer_id,
        location_id,
        query,
      },
      ...axiosConfig,
    },
    SWRConfig,
  );

export const useJobsMyListRequest = (
  {
    offset,
    limit,
    career_zone_id,
    job_type,
    location_id,
    status,
    query,
  }: {
    career_zone_id?: number;
    job_type?: JobType;
    limit?: number;
    location_id?: number;
    offset?: number;
    query?: string;
    status?: number;
  },
  SWRConfig?: SWRConfig<JobResults, ResponseError>,
  axiosConfig?: AxiosRequestConfig,
) =>
  useGetRequest<JobResults, ResponseError>(
    {
      url: `/v1/authed/jobs`,
      method: "get",
      headers: {},
      params: {
        offset,
        limit,
        career_zone_id,
        job_type,
        location_id,
        status,
        query,
      },
      ...axiosConfig,
    },
    SWRConfig,
  );

export const useJobsTrackRequest = (
  {
    jobId,
  }: {
    jobId: number;
  },
  mutationConfig?: SWRMutationConfig<undefined, AxiosResponse<undefined>, ResponseError>,
  axiosConfig?: AxiosRequestConfig,
) =>
  useMutationRequest<undefined, AxiosResponse<undefined>, ResponseError>({
    url: `/v1/jobs/${jobId}/track`,
    method: "post",
    headers: {},
    mutationConfig,
    axiosConfig,
  });

export const useJobsUpdateRequest = (
  {
    jobId,
  }: {
    jobId: number;
  },
  mutationConfig?: SWRMutationConfig<undefined, AxiosResponse<undefined>, ResponseError>,
  axiosConfig?: AxiosRequestConfig,
) =>
  useMutationRequest<undefined, AxiosResponse<undefined>, ResponseError>({
    url: `/v1/authed/jobs/${jobId}`,
    method: "put",
    headers: {},
    mutationConfig,
    axiosConfig,
  });

export const useNewsletterSubscribeRequest = (
  mutationConfig?: SWRMutationConfig<NewsletterSubscribeRequest, AxiosResponse<undefined>, ResponseError>,
  axiosConfig?: AxiosRequestConfig,
) =>
  useMutationRequest<NewsletterSubscribeRequest, AxiosResponse<undefined>, ResponseError>({
    url: `/v1/newsletter/subscribe`,
    method: "post",
    headers: { "Content-Type": "application/json" },
    mutationConfig,
    axiosConfig,
  });

export const usePageGetRequest = (
  {
    slug,
  }: {
    slug: string;
  },
  SWRConfig?: SWRConfig<Page, ResponseError>,
  axiosConfig?: AxiosRequestConfig,
) =>
  useGetRequest<Page, ResponseError>(
    {
      url: `/v1/page/${slug}`,
      method: "get",
      headers: {},
      ...axiosConfig,
    },
    SWRConfig,
  );

export const useProfileGetRequest = (
  SWRConfig?: SWRConfig<Organisation, ResponseError>,
  axiosConfig?: AxiosRequestConfig,
) =>
  useGetRequest<Organisation, ResponseError>(
    {
      url: `/v1/authed/employers/profile`,
      method: "get",
      headers: {},
      ...axiosConfig,
    },
    SWRConfig,
  );

export const useProfileLogoUpdateRequest = (
  mutationConfig?: SWRMutationConfig<undefined, AxiosResponse<undefined>, ResponseError>,
  axiosConfig?: AxiosRequestConfig,
) =>
  useMutationRequest<undefined, AxiosResponse<undefined>, ResponseError>({
    url: `/v1/authed/employers/profile/logo`,
    method: "post",
    headers: {},
    mutationConfig,
    axiosConfig,
  });

export const useProfileTabUpdateRequest = (
  mutationConfig?: SWRMutationConfig<undefined, AxiosResponse<undefined>, ResponseError>,
  axiosConfig?: AxiosRequestConfig,
) =>
  useMutationRequest<undefined, AxiosResponse<undefined>, ResponseError>({
    url: `/v1/authed/employers/profile/tabs/update`,
    method: "post",
    headers: {},
    mutationConfig,
    axiosConfig,
  });

export const useProfileTabsUpdateRequest = (
  mutationConfig?: SWRMutationConfig<undefined, AxiosResponse<undefined>, ResponseError>,
  axiosConfig?: AxiosRequestConfig,
) =>
  useMutationRequest<undefined, AxiosResponse<undefined>, ResponseError>({
    url: `/v1/authed/employers/profile/tabs`,
    method: "post",
    headers: {},
    mutationConfig,
    axiosConfig,
  });

export const useProfileUpdateRequest = (
  mutationConfig?: SWRMutationConfig<undefined, AxiosResponse<undefined>, ResponseError>,
  axiosConfig?: AxiosRequestConfig,
) =>
  useMutationRequest<undefined, AxiosResponse<undefined>, ResponseError>({
    url: `/v1/authed/employers/profile`,
    method: "post",
    headers: {},
    mutationConfig,
    axiosConfig,
  });

export const useSchoolsSearchRequest = (
  {
    term,
  }: {
    term: string;
  },
  SWRConfig?: SWRConfig<SchoolSearchResults, ResponseError>,
  axiosConfig?: AxiosRequestConfig,
) =>
  useGetRequest<SchoolSearchResults, ResponseError>(
    {
      url: `/v1/schools/search`,
      method: "get",
      headers: {},
      params: {
        term,
      },
      ...axiosConfig,
    },
    SWRConfig,
  );

export const useStudentAccountMetadataRequest = (
  SWRConfig?: SWRConfig<undefined, ResponseError>,
  axiosConfig?: AxiosRequestConfig,
) =>
  useGetRequest<undefined, ResponseError>(
    {
      url: `/v1/student/account/metadata`,
      method: "get",
      headers: {},
      ...axiosConfig,
    },
    SWRConfig,
  );

export const useStudentAccountUpdateRequest = (
  mutationConfig?: SWRMutationConfig<undefined, AxiosResponse<undefined>, ResponseError>,
  axiosConfig?: AxiosRequestConfig,
) =>
  useMutationRequest<undefined, AxiosResponse<undefined>, ResponseError>({
    url: `/v1/authed/student/account`,
    method: "post",
    headers: {},
    mutationConfig,
    axiosConfig,
  });

export const useStudentCreateRequest = (
  mutationConfig?: SWRMutationConfig<StudentCreateRequest, AxiosResponse<undefined>, ResponseError>,
  axiosConfig?: AxiosRequestConfig,
) =>
  useMutationRequest<StudentCreateRequest, AxiosResponse<undefined>, ResponseError>({
    url: `/v1/student`,
    method: "post",
    headers: { "Content-Type": "application/json" },
    mutationConfig,
    axiosConfig,
  });

export const useStudentEthnicitiesGetRequest = (
  {
    parentId,
  }: {
    parentId: string;
  },
  SWRConfig?: SWRConfig<undefined, ResponseError>,
  axiosConfig?: AxiosRequestConfig,
) =>
  useGetRequest<undefined, ResponseError>(
    {
      url: `/v1/user/ethnicities/${parentId}`,
      method: "get",
      headers: {},
      ...axiosConfig,
    },
    SWRConfig,
  );

export const useStudentExperienceGetRequest = (
  SWRConfig?: SWRConfig<undefined, ResponseError>,
  axiosConfig?: AxiosRequestConfig,
) =>
  useGetRequest<undefined, ResponseError>(
    {
      url: `/v1/authed/student/experience`,
      method: "get",
      headers: {},
      ...axiosConfig,
    },
    SWRConfig,
  );

export const useStudentFavouritesListRequest = (
  {
    type,
    withDetails,
    limit,
  }: {
    limit?: number;
    type: string;
    withDetails?: boolean;
  },
  SWRConfig?: SWRConfig<undefined, ResponseError>,
  axiosConfig?: AxiosRequestConfig,
) =>
  useGetRequest<undefined, ResponseError>(
    {
      url: `/v1/authed/student/favourites/${type}`,
      method: "get",
      headers: {},
      params: {
        withDetails,
        limit,
      },
      ...axiosConfig,
    },
    SWRConfig,
  );

export const useStudentGradesAddRequest = (
  mutationConfig?: SWRMutationConfig<undefined, AxiosResponse<undefined>, ResponseError>,
  axiosConfig?: AxiosRequestConfig,
) =>
  useMutationRequest<undefined, AxiosResponse<undefined>, ResponseError>({
    url: `/v1/authed/student/grades`,
    method: "post",
    headers: {},
    mutationConfig,
    axiosConfig,
  });

export const useStudentGradesDeleteRequest = (
  {
    gradeId,
  }: {
    gradeId: number;
  },
  mutationConfig?: SWRMutationConfig<undefined, AxiosResponse<undefined>, ResponseError>,
  axiosConfig?: AxiosRequestConfig,
) =>
  useMutationRequest<undefined, AxiosResponse<undefined>, ResponseError>({
    url: `/v1/authed/student/grades/${gradeId}`,
    method: "delete",
    headers: {},
    mutationConfig,
    axiosConfig,
  });

export const useStudentGradesListRequest = (
  SWRConfig?: SWRConfig<Grades, ResponseError>,
  axiosConfig?: AxiosRequestConfig,
) =>
  useGetRequest<Grades, ResponseError>(
    {
      url: `/v1/authed/student/grades`,
      method: "get",
      headers: {},
      ...axiosConfig,
    },
    SWRConfig,
  );

export const useStudentGradesMetadataRequest = (
  {
    type,
    parentId,
  }: {
    parentId?: number;
    type: string;
  },
  SWRConfig?: SWRConfig<StudentGradesMetaData[], ResponseError>,
  axiosConfig?: AxiosRequestConfig,
) =>
  useGetRequest<StudentGradesMetaData[], ResponseError>(
    {
      url: `/v1/student/grades/metadata/${type}/${parentId}`,
      method: "get",
      headers: {},
      ...axiosConfig,
    },
    SWRConfig,
  );

export const useStudentVerifyStep1Request = (
  {
    key,
  }: {
    key: string;
  },
  mutationConfig?: SWRMutationConfig<undefined, AxiosResponse<undefined>, ResponseError>,
  axiosConfig?: AxiosRequestConfig,
) =>
  useMutationRequest<undefined, AxiosResponse<undefined>, ResponseError>({
    url: `/v1/student/verify/${key}/step1`,
    method: "post",
    headers: {},
    mutationConfig,
    axiosConfig,
  });

export const useStudentVerifyStep2Request = (
  {
    key,
  }: {
    key: string;
  },
  mutationConfig?: SWRMutationConfig<undefined, AxiosResponse<undefined>, ResponseError>,
  axiosConfig?: AxiosRequestConfig,
) =>
  useMutationRequest<undefined, AxiosResponse<undefined>, ResponseError>({
    url: `/v1/student/verify/${key}/step2`,
    method: "post",
    headers: {},
    mutationConfig,
    axiosConfig,
  });

export const useStudentVerifyStep3Request = (
  {
    key,
  }: {
    key: string;
  },
  mutationConfig?: SWRMutationConfig<undefined, AxiosResponse<undefined>, ResponseError>,
  axiosConfig?: AxiosRequestConfig,
) =>
  useMutationRequest<undefined, AxiosResponse<undefined>, ResponseError>({
    url: `/v1/student/verify/${key}/step3`,
    method: "post",
    headers: {},
    mutationConfig,
    axiosConfig,
  });

export const useTeacherCreateRequest = (
  mutationConfig?: SWRMutationConfig<TeacherCreateRequest, AxiosResponse<undefined>, ResponseError>,
  axiosConfig?: AxiosRequestConfig,
) =>
  useMutationRequest<TeacherCreateRequest, AxiosResponse<undefined>, ResponseError>({
    url: `/v1/teacher`,
    method: "post",
    headers: { "Content-Type": "application/json" },
    mutationConfig,
    axiosConfig,
  });

export const useTeacherVerifyRequest = (
  {
    key,
  }: {
    key: string;
  },
  mutationConfig?: SWRMutationConfig<undefined, AxiosResponse<undefined>, ResponseError>,
  axiosConfig?: AxiosRequestConfig,
) =>
  useMutationRequest<undefined, AxiosResponse<undefined>, ResponseError>({
    url: `/v1/teacher/verify/${key}`,
    method: "post",
    headers: {},
    mutationConfig,
    axiosConfig,
  });

export const useUniversitiesGetRequest = (
  {
    universityId,
  }: {
    universityId: number;
  },
  SWRConfig?: SWRConfig<Organisation, ResponseError>,
  axiosConfig?: AxiosRequestConfig,
) =>
  useGetRequest<Organisation, ResponseError>(
    {
      url: `/v1/universities/${universityId}`,
      method: "get",
      headers: {},
      ...axiosConfig,
    },
    SWRConfig,
  );

export const useUniversitiesListRequest = (
  SWRConfig?: SWRConfig<UniversityResults, ResponseError>,
  axiosConfig?: AxiosRequestConfig,
) =>
  useGetRequest<UniversityResults, ResponseError>(
    {
      url: `/v1/universities`,
      method: "get",
      headers: {},
      ...axiosConfig,
    },
    SWRConfig,
  );

export const useUniversityCreateRequest = (
  mutationConfig?: SWRMutationConfig<UniversityCreateRequest, AxiosResponse<undefined>, ResponseError>,
  axiosConfig?: AxiosRequestConfig,
) =>
  useMutationRequest<UniversityCreateRequest, AxiosResponse<undefined>, ResponseError>({
    url: `/v1/university`,
    method: "post",
    headers: { "Content-Type": "application/json" },
    mutationConfig,
    axiosConfig,
  });

export const useUserAccountUpdateRequest = (
  mutationConfig?: SWRMutationConfig<undefined, AxiosResponse<undefined>, ResponseError>,
  axiosConfig?: AxiosRequestConfig,
) =>
  useMutationRequest<undefined, AxiosResponse<undefined>, ResponseError>({
    url: `/v1/authed/users/account`,
    method: "post",
    headers: {},
    mutationConfig,
    axiosConfig,
  });

export const useUserForgotPasswordRequest = (
  mutationConfig?: SWRMutationConfig<UserForgotPasswordRequest, AxiosResponse<undefined>, ResponseError>,
  axiosConfig?: AxiosRequestConfig,
) =>
  useMutationRequest<UserForgotPasswordRequest, AxiosResponse<undefined>, ResponseError>({
    url: `/v1/user/forgot`,
    method: "post",
    headers: {},
    mutationConfig,
    axiosConfig,
  });

export const useUserLoginRequest = (
  mutationConfig?: SWRMutationConfig<UserLoginRequest, AxiosResponse<undefined>, ResponseError>,
  axiosConfig?: AxiosRequestConfig,
) =>
  useMutationRequest<UserLoginRequest, AxiosResponse<undefined>, ResponseError>({
    url: `/v1/login`,
    method: "post",
    headers: { "Content-Type": "application/json" },
    mutationConfig,
    axiosConfig,
  });

export const useUserLogoutRequest = (
  mutationConfig?: SWRMutationConfig<undefined, AxiosResponse<undefined>, ResponseError>,
  axiosConfig?: AxiosRequestConfig,
) =>
  useMutationRequest<undefined, AxiosResponse<undefined>, ResponseError>({
    url: `/v1/logout`,
    method: "post",
    headers: {},
    mutationConfig,
    axiosConfig,
  });

export const useUserProfileGetRequest = (
  SWRConfig?: SWRConfig<undefined, ResponseError>,
  axiosConfig?: AxiosRequestConfig,
) =>
  useGetRequest<undefined, ResponseError>(
    {
      url: `/v1/authed/users/account`,
      method: "get",
      headers: {},
      ...axiosConfig,
    },
    SWRConfig,
  );

export const useUserResetPasswordRequest = (
  {
    key,
  }: {
    key: string;
  },
  mutationConfig?: SWRMutationConfig<undefined, AxiosResponse<undefined>, ResponseError>,
  axiosConfig?: AxiosRequestConfig,
) =>
  useMutationRequest<undefined, AxiosResponse<undefined>, ResponseError>({
    url: `/v1/user/reset/${key}`,
    method: "post",
    headers: {},
    mutationConfig,
    axiosConfig,
  });

export const useUserVerifyRequest = (
  {
    key,
  }: {
    key: string;
  },
  mutationConfig?: SWRMutationConfig<undefined, AxiosResponse<undefined>, ResponseError>,
  axiosConfig?: AxiosRequestConfig,
) =>
  useMutationRequest<undefined, AxiosResponse<undefined>, ResponseError>({
    url: `/v1/user/verify/${key}`,
    method: "post",
    headers: {},
    mutationConfig,
    axiosConfig,
  });

export const useUserVerifyGetRequest = (
  {
    key,
  }: {
    key: string;
  },
  SWRConfig?: SWRConfig<undefined, ResponseError>,
  axiosConfig?: AxiosRequestConfig,
) =>
  useGetRequest<undefined, ResponseError>(
    {
      url: `/v1/user/verify/${key}`,
      method: "get",
      headers: {},
      ...axiosConfig,
    },
    SWRConfig,
  );

export interface ApprenticeCreateRequest {
  body: UserForRegistration;
}

export interface ApprenticesListRequest {
  query: {
    term?: string;
  };
}

export interface ArticlesListRequest {
  query: {
    article_type: ArticleType;
    category_id?: number;
    limit?: number;
    offset?: number;
    query?: string;
  };
}

export interface EmployerCreateRequest {
  body: UserForRegistration;
}

export interface EmployersListRequest {
  query: {
    career_zone_id?: number;
    term?: string;
  };
}

export interface ForumsAddTopicRequest {
  body: NewForumPostTopic;
}

export interface ForumsCommentAddRequest {
  body: ForumTopicComment;
}

export interface ForumsCommentRepliesRequest {
  query: {
    sort?: string;
  };
}

export interface ForumsCommentReportRequest {
  body: ReportComment;
  query: {
    schoolId?: number;
  };
}

export interface ForumsEditCommentRequest {
  body: EditForumPostComment;
}

export interface ForumsEditTopicRequest {
  body: EditForumPostTopic;
}

export interface ForumsListRequest {
  query: {
    employerId?: number;
    limit?: number;
    offset?: number;
    regionId?: number;
    sectorId?: number;
  };
}

export interface ForumsSubscribeRequest {
  query: {
    employerId?: number;
    regionId?: number;
    sectorId?: number;
  };
}

export interface ForumsTopicRequest {
  query: {
    sort?: string;
  };
}

export interface ForumsTopicsListRequest {
  query: {
    limit?: number;
    offset?: number;
    query?: string;
    sort?: string;
    tag?: string;
  };
}

export interface JobsListRequest {
  query: {
    career_zone_ids?: number[];
    employer_id?: number;
    featured?: boolean;
    job_type?: JobType;
    limit?: number;
    location_id?: number;
    offset?: number;
    query?: string;
  };
}

export interface JobsMyListRequest {
  query: {
    career_zone_id?: number;
    job_type?: JobType;
    limit?: number;
    location_id?: number;
    offset?: number;
    query?: string;
    status?: number;
  };
}

export interface NewsletterSubscribeRequest {
  body: NewsletterSubscriber;
}

export interface SchoolsSearchRequest {
  query: {
    term: string;
  };
}

export interface StudentCreateRequest {
  body: UserForRegistration;
}

export interface StudentFavouritesListRequest {
  query: {
    limit?: number;
    withDetails?: boolean;
  };
}

export interface TeacherCreateRequest {
  body: UserForRegistration;
}

export interface UniversityCreateRequest {
  body: UserForRegistration;
}

export interface UserForgotPasswordRequest {
  query: {
    email: string;
  };
}

export interface UserLoginRequest {
  body: UserLoginCredentials;
}

export interface ApprenticeResults {
  apprentices?: Organisation[];
}

export interface Article {
  category?: string;
  content: string;
  content_snippet?: string;
  heading_uri?: string;
  id: number;
  image_uri?: string;
  meta_description?: string;
  meta_keywords?: string;
  meta_title?: string;
  restrictedAccess?: boolean;
  title: string;
  type?: ArticleType;
}

export interface ArticleCategory {
  id?: number;
  imageUri?: string;
  name?: string;
}

export interface ArticleResults {
  articles?: Article[];
  total_count?: number;
}

export enum ArticleType {
  "advice" = "advice",
  "news" = "news",
}

export interface CareerZone {
  details?: CareerZoneDetail[];
  header_image?: string;
  icon_path?: string;
  id?: number;
  name?: string;
  overview?: string;
}

export interface CareerZoneDetail {
  content: string;
  title: string;
}

export interface CareerZoneResults {
  career_zones?: CareerZone[];
}

export enum CourseType {
  "Summer Schools" = "Summer Schools",
}

export interface EditForumPostComment {
  content?: string;
}

export interface EditForumPostTopic {
  content?: string;
  title?: string;
}

export interface EmployerResults {
  employers?: Organisation[];
}

export interface ForumList {
  buttonWording?: string;
  category?: ForumSubCategory;
  isBan?: boolean;
  isPartner?: boolean;
  isSubscribed?: boolean;
  popularTags?: ForumTag[];
  subCategories?: ForumSubCategory[];
  textWording?: string;
  topicCount?: number;
}

export interface ForumPostTopic {
  content?: string;
  createdBy?: User;
  createdDate?: string;
  forumTopicId?: number;
  groupSetId?: number;
  isNew?: boolean;
  isSticky?: boolean;
  likes?: number;
  schoolId?: number;
  subCatId?: number;
  title?: string;
  updatedDate?: string;
  viewCount?: number;
}

export interface ForumSubCategory {
  description?: string;
  employerId?: number | null;
  image?: string;
  linkedId?: string | null;
  name?: string;
  parentChain?: number[];
  parentId?: number;
  regionId?: number | null;
  sectorId?: number | null;
  subCatId?: number;
}

export interface ForumTag {
  id?: number;
  name?: string;
}

export interface ForumTopic {
  commentAllow?: boolean;
  comments?: ForumTopicComment[];
  isSchoolParent?: boolean;
  subCat?: ForumSubCategory;
  topic?: ForumPostTopic;
}

export interface ForumTopicComment {
  commentApprovalFlag?: number;
  commentContent?: string;
  createdDate?: string;
  forumCommentUserFk?: User;
  id: number;
  isNew?: string;
  likes?: number;
  parentChain?: string;
  parentId?: number;
  parentTopicId?: ForumPostTopic;
  topicId?: number;
  updatedDate?: string;
}

export interface Grades {
  [key: string]: any;
}

export interface Job {
  applyCount?: number;
  applyLink?: string;
  applyLinkTracked?: string;
  approvalFlag?: JobApprovalFlag;
  careerZones?: CareerZone[];
  courseEndDate?: string;
  courseStartDate?: string;
  createdDate?: string;
  deadline?: string;
  description?: string;
  hideDeadline?: boolean;
  id: number;
  jobLocation?: number;
  jobType?: JobType | CourseType;
  jobTypeAsJobPostingMetadata?: JobJobTypeAsJobPostingMetadata;
  locationName?: string;
  metaDescription?: string;
  metaKeywords?: string;
  metaTitle?: string;
  organisation?: Organisation;
  salaryFee?: string;
  shortDesc?: string;
  status?: string;
  timeEnd?: string;
  timePeriod?: number;
  timeStart?: string;
  title: string;
  type?: JobType;
  viewCount?: number;
}

export interface JobApplication {
  applyDate?: string;
  id: number;
  job: Job;
  notes?: string;
  stage?: string;
  status?: string;
}

export interface JobApplicationResults {
  applications?: JobApplication[];
}

export type JobApprovalFlag = 0 | 1 | 2;

export enum JobJobTypeAsJobPostingMetadata {
  "FULL_TIME" = "FULL_TIME",
  "OTHER" = "OTHER",
}

export interface JobMetadataResults {
  career_zones?: CareerZone[];
  course_types?: CourseType[];
  job_types?: JobType[];
  locations?: Location[];
  statuses?: JobStatus[];
}

export interface JobResults {
  jobs?: Job[];
  total_count?: number;
}

export interface JobStatus {
  [key: string]: any;
}

export enum JobType {
  "Apprenticeship" = "Apprenticeship",
  "Intermediate Apprenticeship" = "Intermediate Apprenticeship",
  "Advanced Apprenticeship" = "Advanced Apprenticeship",
  "Higher Apprenticeship" = "Higher Apprenticeship",
  "Degree Apprenticeship" = "Degree Apprenticeship",
  "School Leaver" = "School Leaver",
  "Gap Years" = "Gap Years",
  "Sponsorship" = "Sponsorship",
  "Work Experience" = "Work Experience",
  "Other" = "Other",
}

export interface Location {
  id?: number;
  name?: string;
}

export interface NewForumPostTopic {
  content?: string;
  title?: string;
}

export interface NewsletterSubscriber {
  email: string;
  firstName: string;
  lastName: string;
}

export interface Organisation {
  address?: string;
  career_zones?: CareerZone[];
  city?: string;
  description_short?: string;
  details?: OrganisationDetail[];
  duration_end?: string;
  duration_start?: string;
  employee_count?: number;
  established_year?: string;
  header_uri?: string;
  id: number;
  locations?: Location[];
  logo_uri?: string;
  name: string;
  postcode?: string;
  twitter?: string;
  type: OrganisationType;
  uri?: string;
  website_title?: string;
}

export interface OrganisationDetail {
  content: string;
  title: string;
}

export enum OrganisationType {
  "employer" = "employer",
  "university" = "university",
}

export interface Page {
  content: string;
  metaDescription?: string;
  metaTags?: string;
  pageId?: number;
  pageKey?: string;
  title: string;
  updatedBy?: string;
}

export interface ReportComment {
  report_reason?: string;
}

export interface School {
  id?: number;
  name_and_info?: string;
}

export interface SchoolSearchResults {
  html_test?: string;
  schools?: School[];
}

export interface StudentGradesMetaData {
  id?: number;
  name?: string;
}

export interface UniversityResults {
  universities?: Organisation[];
}

export interface User {
  [key: string]: any;
}

export interface UserForRegistration {
  dateOfBirth?: string;
  email: string;
  gender?: string;
  gender_name?: string;
  id?: number;
  personal_email?: string;
  phone?: string;
  position?: string;
  rawPassword?: string;
  schoolId?: number;
  username?: string;
}

export interface UserLoginCredentials {
  email: string;
  password: string;
}
