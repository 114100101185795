import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";

import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import { makeStyles } from "tss-react/mui";
import Creatable from "react-select/creatable";

import Check from "@mui/icons-material/Check";
import Warning from "@mui/icons-material/Warning";

import { client, prepareCaptchaInterceptor } from "../../request/client";
import Button from "../CustomButtons/Button";
import SnackbarContent from "../Snackbar/SnackbarContent";

import {
  useApprenticeCreateRequest,
  useEmployersListRequest,
  UserForRegistration,
} from "../../request/api";

import styles from "../../styles/jss/nextjs-material-kit/components/modalForms.js";
import { FormHelperText, TextField } from "@mui/material";
import Grid from "@mui/material/Grid";

const useStyles = makeStyles()(styles);

export default function ApprenticeRegisterForm({ closeFromInside, email }) {
  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState({});

  /**
   * @type {UserForRegistration}
   */
  const [registration, setRegistration] = useState({
    first_name: "",
    last_name: "",
    username: "",
    organizationname: "",
    password: "",
    position: "",
    phone: "",
    email: email,
    receive_info_email: true,
  });

  const [registerError, setRegisterError] = useState(null);

  const [organisationActiveOption, setOrganisationActiveOption] =
    useState(null);
  const [organisationOptions, setOrganisationOptions] = useState([]);
  const [organisationSearchQuery, setOrganisationSearchQuery] = useState("");

  const { classes } = useStyles();
  const recaptchaRef = useRef(null);
  const recaptchaSiteKey = process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY;
  const { isMutating, trigger: registerEmployer } =
    useApprenticeCreateRequest();
  const { data, isLoading, mutate } = useEmployersListRequest({
    term: organisationSearchQuery,
  });

  useEffect(() => {
    window.gtag && window.gtag("event", "employer_register_form");
  }, []);

  const handleInputChange = (event) => {
    setRegistration((registration) => ({
      ...registration,
      [event.target.name.replace("register_", "")]: event.target.value,
    }));
  };

  useEffect(() => {
    // Update iff the search query changed, to minimise performance impact.
    mutate();
  }, [organisationSearchQuery]);

  useEffect(() => {
    if (!data) {
      return; // Undefined while loading.
    }

    if (organisationOptions.length > 0 && data.employers.length === 0) {
      // For now, don't clear updates which take us to 0 options. It seems like there are edge cases
      // where past results from one change ago can clear options unhelpfully as you type.
      return;
    }

    setOrganisationOptions(
      data.employers
        ? data.employers.map((employer) => {
            return {
              label: employer.name,
              value: employer.id,
            };
          })
        : []
    );
  }, [data]);

  const organisationChosen = (option) => {
    setOrganisationActiveOption(option);
    setRegistration((registration) => ({
      ...registration,
      organizationname: option.label,
    }));
  };

  const organisationCleared = () => {
    setOrganisationActiveOption(null);
    setRegistration((registration) => ({
      ...registration,
      organizationname: null,
    }));
  };

  const updateEmailMarketingOptIn = (event) => {
    event.preventDefault();
    setRegistration((registration) => ({
      ...registration,
      receive_info_email: !registration.receive_info_email,
    }));
  };

  /**
   * Order of steps for successful registration:
   * 1. Submit pressed: `prepareCaptchaAndRegister()`
   * 2. Captcha solved: `handleCaptchaChange()`
   * 3. Captcha new value callback sets token and calls `register()`
   */
  const prepareCaptchaAndRegister = (event) => {
    event.preventDefault();
    recaptchaRef.current.reset(); // Make it work even if a past solution failed or timed out.
    recaptchaRef.current.execute(); // Get a token invisibly, or via a challenge if necessary.
  };

  const resetCaptcha = () => {
    // registrationComplete being true should cause the callback to auto-login
    // instead of trying to register again.
    recaptchaRef.current.reset();
    recaptchaRef.current.execute();
  };

  /**
   * @param {?string} captchaCode
   */
  const handleCaptchaChange = (captchaCode) => {
    if (!captchaCode) {
      return;
    }

    // We pass this to `register()` directly because the timing didn't work when
    // using a setter and `useState(...)` while also naively calling a submission
    // method right after.
    register(captchaCode);
  };

  /**
   * @param {?string} captchaCode
   */
  const register = async (captchaCode) => {
    setErrors({});
    setRegisterError("");

    prepareCaptchaInterceptor(client, captchaCode);
    try {
      await registerEmployer({
        body: registration,
      });

      window.gtag && window.gtag("event", "employer_register_submit");

      setSuccess(true);
    } catch (e) {
      setRegisterError(
        e.response?.data?.errors?.length > 0
          ? e.response?.data?.errors[0]
          : e.response?.data?.message || e.message
      );
      setErrors(e.response.data.errors);
    }
  };

  if (success) {
    return (
      <>
        <SnackbarContent
          message="Registration successful, please check your email for a verification link."
          color="success"
          icon={Check}
        />
      </>
    );
  }

  return (
    <div className={classes.container}>
      <form className={classes.form} onSubmit={prepareCaptchaAndRegister}>
        <ReCAPTCHA
          sitekey={recaptchaSiteKey}
          onChange={handleCaptchaChange}
          ref={recaptchaRef}
          size="invisible"
        />

        <Grid container spacing={4}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <label htmlFor="first_name" className={classes.label}>
                First name
              </label>
              <TextField
                error={!!errors?.first_name}
                helperText={errors?.first_name}
                required
                id="first_name"
                name="first_name"
                type="text"
                value={registration.first_name}
                onChange={handleInputChange}
                variant="outlined"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <label htmlFor="last_name" className={classes.label}>
                Last name
              </label>
              <TextField
                error={!!errors?.last_name}
                helperText={errors?.last_name}
                required
                id="last_name"
                name="last_name"
                type="text"
                value={registration.last_name}
                onChange={handleInputChange}
                variant="outlined"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <label htmlFor="email" className={classes.label}>
                Email
              </label>
              <TextField
                error={!!errors?.email}
                helperText={errors?.email}
                required
                id="email"
                name="email"
                type="email"
                value={registration.email}
                onChange={handleInputChange}
                variant="outlined"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <label htmlFor="username" className={classes.label}>
                Username
              </label>
              <TextField
                error={!!errors?.username}
                helperText={errors?.username}
                required
                id="username"
                name="username"
                type="text"
                value={registration.username}
                onChange={handleInputChange}
                variant="outlined"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <label htmlFor="email" className={classes.label}>
                Password
              </label>
              <TextField
                error={!!errors?.password}
                helperText={errors?.password}
                required
                id="password"
                name="password"
                type="password"
                inputProps={{ minLength: 8 }}
                value={registration.password}
                onChange={handleInputChange}
                variant="outlined"
              />
            </FormControl>
          </Grid>
          {/*<Grid item xs={12}>*/}
          {/*  <label htmlFor={"schoolSelect"} className={classes.label}>*/}
          {/*    Organisation Name*/}
          {/*  </label>*/}
          {/*  <Creatable*/}
          {/*    id={"organisationSelect"}*/}
          {/*    isLoading={isLoading}*/}
          {/*    onChange={organisationChosen}*/}
          {/*    onInputChange={(val) => setOrganisationSearchQuery(val)}*/}
          {/*    onMenuOpen={organisationCleared}*/}
          {/*    options={organisationOptions}*/}
          {/*    placeholder={"Select or Search by Name…"}*/}
          {/*    value={organisationActiveOption}*/}
          {/*  />*/}
          {/*  <FormHelperText error={!!errors?.school}>*/}
          {/*    {errors?.school}*/}
          {/*  </FormHelperText>*/}
          {/*</Grid>*/}
          <Grid item xs={12}>
            <FormControl fullWidth>
              <label htmlFor="position" className={classes.label}>
                Position
              </label>
              <TextField
                error={!!errors?.position}
                helperText={errors?.position}
                required
                id="position"
                name="position"
                type="text"
                value={registration.position}
                onChange={handleInputChange}
                variant="outlined"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <label htmlFor="phone" className={classes.label}>
                Phone
              </label>
              <TextField
                error={!!errors?.phone}
                helperText={errors?.phone}
                required
                id="phone"
                name="phone"
                type="text"
                value={registration.phone}
                onChange={handleInputChange}
                variant="outlined"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  tabIndex={-1}
                  checked={registration.receive_info_email}
                  onClick={updateEmailMarketingOptIn}
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot,
                  }}
                />
              }
              classes={{ root: classes.labelRoot }}
              label="Stay in the loop with our latest news, updates and research. Opt-out by unchecking the box if you prefer not to receive these updates."
            />
          </Grid>
          <Grid item xs={12}>
            {registerError ? (
              <SnackbarContent
                message={registerError}
                color="warning"
                icon={Warning}
              />
            ) : null}
          </Grid>
          <Grid item xs={12}>
            <Button
              color="primary"
              type={"submit"}
              disabled={isMutating}
              fullWidth
            >
              Register
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}

ApprenticeRegisterForm.propTypes = {
  closeFromInside: PropTypes.func.isRequired,
};
