import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import FormControl from "@mui/material/FormControl";
import { makeStyles } from "tss-react/mui";

import Check from "@mui/icons-material/Check";
import Warning from "@mui/icons-material/Warning";

import { client, prepareCaptchaInterceptor } from "../../request/client";
import Button from "../CustomButtons/Button";
import SnackbarContent from "../Snackbar/SnackbarContent";

import {
  UserForRegistration,
  useStudentVerifyStep1Request,
  useStudentVerifyGetRequest,
  useTeacherVerifyRequest,
} from "../../request/api";

import styles from "../../styles/jss/nextjs-material-kit/components/modalForms.js";
import { FormHelperText, RadioGroup, TextField } from "@mui/material";
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
const useStyles = makeStyles()(styles);

export default function VerifyTeacherForm({
  hash,
  verifyData,
  isLoading,
  triggerNextStep,
  userVerifyError,
  closeFromInside,
}) {
  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState({});

  /**
   * @type {UserForRegistration}
   */
  const [registration, setRegistration] = useState({
    first_name: "",
    last_name: "",
    username: "",
    email: "",
    password: {
      first: "",
      second: "",
    },
    personal_email: "",
    schadminphone: "",
  });

  const [errorMessage, setErrorMessage] = useState(null);
  const [isPersonalEmail, setIsPersonalEmail] = useState(true);

  const { classes } = useStyles();
  const recaptchaRef = useRef(null);
  const recaptchaSiteKey = process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY;
  const { isMutating, trigger: save } = useTeacherVerifyRequest({ key: hash });

  useEffect(() => {
    window.gtag && window.gtag("event", "teacher_verify_step1_form");
  }, []);

  const handleInputChange = (event) => {
    setRegistration((registration) => ({
      ...registration,
      [event.target.name.replace("register_", "")]: event.target.value,
    }));
  };

  const handlePasswordChange = (event) => {
    setRegistration((registration) => ({
      ...registration,
      password: {
        ...registration.password,
        [event.target.name.replace("password_", "")]: event.target.value,
      },
    }));
  };

  const handlePersonalEmailChange = (event) => {
    setIsPersonalEmail(event.target.value === "true");
  };

  /**
   * Order of steps for successful registration:
   * 1. Submit pressed: `prepareCaptchaAndSubmit()`
   * 2. Captcha solved: `handleCaptchaChange()`
   * 3. Captcha new value callback sets token and calls `register()`
   */
  const prepareCaptchaAndSubmit = (event) => {
    event.preventDefault();
    recaptchaRef.current.reset(); // Make it work even if a past solution failed or timed out.
    recaptchaRef.current.execute(); // Get a token invisibly, or via a challenge if necessary.
  };

  const resetCaptcha = () => {
    // registrationComplete being true should cause the callback to auto-login
    // instead of trying to register again.
    recaptchaRef.current.reset();
    recaptchaRef.current.execute();
  };

  /**
   * @param {?string} captchaCode
   */
  const handleCaptchaChange = (captchaCode) => {
    if (!captchaCode) {
      return;
    }

    // We pass this to `register()` directly because the timing didn't work when
    // using a setter and `useState(...)` while also naively calling a submission
    // method right after.
    submit(captchaCode);
  };

  /**
   * @param {?string} captchaCode
   */
  const submit = async (captchaCode) => {
    setErrors({});
    setErrorMessage("");

    prepareCaptchaInterceptor(client, captchaCode);
    try {
      const response = await save({
        body: registration,
      });

      window.gtag && window.gtag("event", "teacher_verify_step1_submit");

      if (response.data.success) {
        setSuccess(true);

        triggerNextStep();
      } else {
        setErrorMessage(response.data.error);
      }
    } catch (e) {
      setErrorMessage(
        e.response?.data?.errors?.length > 0
          ? e.response?.data?.errors[0]
          : e.response?.data?.message || e.message
      );
      setErrors(e.response.data.errors);
    }
  };

  if (isLoading) {
    return <>Loading...</>;
  }

  return (
    <div className={classes.container}>
      <form className={classes.form} onSubmit={prepareCaptchaAndSubmit}>
        <ReCAPTCHA
          sitekey={recaptchaSiteKey}
          onChange={handleCaptchaChange}
          ref={recaptchaRef}
          size="invisible"
        />

        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <label htmlFor="first_name" className={classes.label}>
                First name *
              </label>
              <TextField
                error={!!errors?.first_name}
                helperText={errors?.first_name}
                required
                id="first_name"
                name="first_name"
                type="text"
                value={registration.first_name}
                onChange={handleInputChange}
                variant="outlined"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <label htmlFor="last_name" className={classes.label}>
                Last name *
              </label>
              <TextField
                error={!!errors?.last_name}
                helperText={errors?.last_name}
                required
                id="last_name"
                name="last_name"
                type="text"
                value={registration.last_name}
                onChange={handleInputChange}
                variant="outlined"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <label htmlFor="username" className={classes.label}>
                Username *
              </label>
              <TextField
                error={!!errors?.username}
                helperText={errors?.username}
                required
                id="username"
                name="username"
                type="text"
                value={registration.username}
                onChange={handleInputChange}
                variant="outlined"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}></Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <label htmlFor="schadminphone" className={classes.label}>
                Mobile *
              </label>
              <TextField
                error={!!errors?.schadminphone}
                helperText={errors?.schadminphone}
                required
                id="schadminphone"
                name="schadminphone"
                type="tel"
                value={registration.schadminphone}
                onChange={handleInputChange}
                variant="outlined"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}></Grid>
          <Grid item xs={12} md={6}>
            {isPersonalEmail ? (
              <>
                <FormControl fullWidth>
                  <label htmlFor="email" className={classes.label}>
                    Email *
                  </label>
                  <TextField
                    required
                    disabled={true}
                    id="email"
                    name="email"
                    type="email"
                    value={verifyData?.email}
                    variant="outlined"
                  />
                </FormControl>
              </>
            ) : (
              <>
                <FormControl fullWidth>
                  <label htmlFor="email" className={classes.label}>
                    Personal Email *
                  </label>
                  <TextField
                    error={!!errors?.personal_email}
                    helperText={errors?.personal_email}
                    required
                    id="personal_email"
                    name="personal_email"
                    type="email"
                    value={registration.personal_email}
                    onChange={handleInputChange}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <label htmlFor="email" className={classes.label}>
                Is this your personal email address? *
              </label>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={isPersonalEmail}
                onChange={handlePersonalEmailChange}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label="Yes"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <label htmlFor="password_first" className={classes.label}>
                Password *
              </label>
              <TextField
                error={!!errors?.first}
                helperText={errors?.first}
                required
                id="password_first"
                name="password_first"
                type="password"
                value={registration.password.first}
                onChange={handlePasswordChange}
                variant="outlined"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <label htmlFor="password_second" className={classes.label}>
                Repeat Password *
              </label>
              <TextField
                error={!!errors?.second}
                helperText={errors?.second}
                required
                id="password_second"
                name="password_second"
                type="password"
                value={registration.password.second}
                onChange={handlePasswordChange}
                variant="outlined"
              />
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            {errorMessage ? (
              <SnackbarContent
                message={errorMessage}
                color="warning"
                icon={Warning}
              />
            ) : (
              <>
                <div>* required field</div>
              </>
            )}
          </Grid>
          <Grid item xs={12}>
            <Button
              color="primary"
              type={"submit"}
              disabled={isMutating}
              fullWidth
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}

VerifyTeacherForm.propTypes = {
  closeFromInside: PropTypes.func.isRequired,
};
