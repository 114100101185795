import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import Select from "react-select";

import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import { makeStyles } from "tss-react/mui";

import Check from "@mui/icons-material/Check";
import Warning from "@mui/icons-material/Warning";

import { client, prepareCaptchaInterceptor } from "../../request/client";
import Button from "../CustomButtons/Button";
import SnackbarContent from "../Snackbar/SnackbarContent";

import {
  School,
  UserForRegistration,
  useSchoolsSearchRequest,
  useStudentCreateRequest,
  useUserLoginRequest,
} from "../../request/api";

import styles from "../../styles/jss/nextjs-material-kit/components/modalForms.js";
import { FormHelperText, TextField } from "@mui/material";
import Grid from "@mui/material/Grid";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import moment from "moment/moment";
const useStyles = makeStyles()(styles);

export default function StudentRegisterForm({ closeFromInside, email }) {
  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState({});

  /**
   * @type {UserForRegistration}
   */
  const [registration, setRegistration] = useState({
    dob: null,
    email: email,
    receive_info_email: true,
    school: null,
  });

  const [registerError, setRegisterError] = useState(null);

  const [schoolActiveOption, setSchoolActiveOption] = useState(null);
  const [schoolOptions, setSchoolOptions] = useState([]);
  const [schoolSearchQuery, setSchoolSearchQuery] = useState("");

  const { classes } = useStyles();
  const recaptchaRef = useRef(null);
  const recaptchaSiteKey = process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY;
  const { isMutating, trigger: registerStudent } = useStudentCreateRequest();
  const { data, isLoading, mutate } = useSchoolsSearchRequest({
    term: schoolSearchQuery,
  });

  useEffect(() => {
    window.gtag && window.gtag("event", "student_register_form");
  }, []);

  useEffect(() => {
    // Update iff the search query changed, to minimise performance impact.
    mutate();
  }, [schoolSearchQuery]);

  useEffect(() => {
    if (!data) {
      return; // Undefined while loading.
    }

    if (schoolOptions.length > 0 && data.schools.length === 0) {
      // For now, don't clear updates which take us to 0 options. It seems like there are edge cases
      // where past results from one change ago can clear options unhelpfully as you type.
      return;
    }

    setSchoolOptions(
      data.schools
        ? data.schools.map((school) => {
            return {
              label: school.name_and_info,
              value: school.id,
            };
          })
        : []
    );
  }, [data]);

  const handleInputChange = (event) => {
    setRegistration((registration) => ({
      ...registration,
      [event.target.name.replace("register_", "")]: event.target.value,
    }));
  };

  const dobChosen = (momentDate) => {
    setRegistration((registration) => ({
      ...registration,
      dob: momentDate.format("YYYY-MM-DD"),
    }));
  };

  const schoolChosen = (option) => {
    setSchoolActiveOption(option);
    setRegistration((registration) => ({
      ...registration,
      school: option.value,
    }));
  };

  const schoolCleared = () => {
    setSchoolActiveOption(null);
    setRegistration((registration) => ({
      ...registration,
      school: null,
    }));
  };

  const updateEmailMarketingOptIn = (event) => {
    event.preventDefault();
    setRegistration((registration) => ({
      ...registration,
      receive_info_email: !registration.receive_info_email,
    }));
  };

  /**
   * Order of steps for successful registration:
   * 1. Submit pressed: `prepareCaptchaAndRegister()`
   * 2. Captcha solved: `handleCaptchaChange()`
   * 3. Captcha new value callback sets token and calls `register()`
   */
  const prepareCaptchaAndRegister = (event) => {
    event.preventDefault();
    recaptchaRef.current.reset(); // Make it work even if a past solution failed or timed out.
    recaptchaRef.current.execute(); // Get a token invisibly, or via a challenge if necessary.
  };

  const resetCaptcha = () => {
    // registrationComplete being true should cause the callback to auto-login
    // instead of trying to register again.
    recaptchaRef.current.reset();
    recaptchaRef.current.execute();
  };

  /**
   * @param {?string} captchaCode
   */
  const handleCaptchaChange = (captchaCode) => {
    if (!captchaCode) {
      return;
    }

    // We pass this to `register()` directly because the timing didn't work when
    // using a setter and `useState(...)` while also naively calling a submission
    // method right after.
    register(captchaCode);
  };

  /**
   * @param {?string} captchaCode
   */
  const register = async (captchaCode) => {
    setErrors({});
    setRegisterError("");

    prepareCaptchaInterceptor(client, captchaCode);
    try {
      await registerStudent({
        body: registration,
      });

      window.gtag && window.gtag("event", "student_register_submit");

      setSuccess(true);
    } catch (e) {
      setRegisterError(
        e.response?.data?.errors?.length > 0
          ? e.response?.data?.errors[0]
          : e.response?.data?.message || e.message
      );
      setErrors(e.response.data.errors);
    }
  };

  if (success) {
    return (
      <>
        <SnackbarContent
          message="Registration successful, please check your email for a verification link."
          color="success"
          icon={Check}
        />
      </>
    );
  }

  return (
    <div className={classes.container}>
      <form className={classes.form} onSubmit={prepareCaptchaAndRegister}>
        <ReCAPTCHA
          sitekey={recaptchaSiteKey}
          onChange={handleCaptchaChange}
          ref={recaptchaRef}
          size="invisible"
        />

        <Grid container spacing={4}>
          <Grid item xs={12}>
            <label htmlFor={"schoolSelect"} className={classes.label}>
              School
            </label>
            <Select
              id={"schoolSelect"}
              isLoading={isLoading}
              onChange={schoolChosen}
              onInputChange={(val) => setSchoolSearchQuery(val)}
              onMenuOpen={schoolCleared}
              options={schoolOptions}
              placeholder={"Select or Search by Name/Post Code…"}
              value={schoolActiveOption}
            />
            <FormHelperText error={!!errors?.school}>
              {errors?.school}
            </FormHelperText>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <label htmlFor="email" className={classes.label}>
                Email
              </label>
              <TextField
                error={!!errors?.email}
                helperText={errors?.email}
                required
                id="email"
                name="email"
                type="email"
                value={registration.email}
                onChange={handleInputChange}
                variant="outlined"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <label htmlFor="jobDeadline" className={classes.label}>
                Date of birth *
              </label>
              <DesktopDatePicker
                required
                onChange={dobChosen}
                variant="outlined"
                format="DD/MM/YYYY"
                maxDate={moment().add(13, "years")}
                slotProps={{
                  textField: {
                    required: true,
                    id: "dob",
                    name: "dob",
                  },
                }}
                // value={job.dob}
                renderInput={(params) => <TextField {...params} />}
              />
              <FormHelperText error={!!errors?.dob}>
                {errors?.dob}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  tabIndex={-1}
                  checked={registration.receive_info_email}
                  onClick={updateEmailMarketingOptIn}
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot,
                  }}
                />
              }
              classes={{ root: classes.labelRoot }}
              label="Stay in the loop with our latest career advice, opportunities, apprenticeships, courses, and events. Opt-out by unchecking the box if you prefer not to receive these updates."
            />
          </Grid>
          <Grid item xs={12}>
            {registerError ? (
              <SnackbarContent
                message={registerError}
                color="warning"
                icon={Warning}
              />
            ) : null}
          </Grid>
          <Grid item xs={12}>
            <Button
              color="primary"
              type={"submit"}
              disabled={isMutating}
              fullWidth
            >
              Register
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}

StudentRegisterForm.propTypes = {
  closeFromInside: PropTypes.func.isRequired,
};
