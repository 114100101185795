import {
  container,
  primaryColor,
  secondaryColor,
  tertiaryColor,
  tertiaryColorTint,
  dangerColor,
  successColor,
  infoColor,
  roseColor,
  warningColor,
  grayColor,
  title,
  boxShadow,
} from "../../nextjs-material-kit.js";
import { keyframes } from '@mui/system';

const lightGrey = "#D2D2D2";
const lighterGrey = "#f7f7f7";
const closeButtonGrey = "rgba(0, 0, 0, 0.1)";
const fadedPrimaryColor = "rgba(9, 90, 112, 0.15)";
const white = "#FFFFFF"
const bannerBlue = "#eaf6fe";

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const buttonOverride = {
  boxShadow: "none !important",
  textDecoration: "none",
  borderRadius: "50px !important",
  "&:hover": {
    boxShadow: "none !important"
  },
  "&:focus": {
    boxShadow: "none !important"
  },
  "&:active": {
    boxShadow: "none !important"
  },
  "&:clicked": {
    boxShadow: "none !important"
  }
};

const linkOverride = {
  textDecoration: "none",
  "&:hover": {
    textDecoration: "none",
  },
  "&:focus": {
    textDecoration: "none",
  },
  "&:active": {
    textDecoration: "none",
  },
  "&:clicked": {
    textDecoration: "none",
  }
}

const forumsStyle = (theme) => ({

  ////////////// Default/previous styles //////////////
  container: {
    marginBottom: "7%",
    zIndex: "12",
    height: "auto",
    margin: "auto",
    width: "100%",
    ...container(theme),
  },
  darkBlue: {
    backgroundColor: primaryColor,
  },
  footer: {
    height: "55px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },

  ////////////// Viewport size variants //////////////
  desktopView: {
    [theme.breakpoints.down('md')]: {
      display: "none",
    },
  },
  mobileView: {
    [theme.breakpoints.up('md')]: {
      display: "none",
    },
  },

  ////////////// Modal widths //////////////
  width30: {
    width: "30%",
  },
  width40: {
    width: "40%",
  },
  width50: {
    width: "50%",
  },
  ////////////// Button styling overrides //////////////
  buttonOverride: {
    ...buttonOverride,
  },
  transparentButton: {
    ...buttonOverride,
    backgroundColor: "transparent",
    color: `${primaryColor} !important`,
    "&:hover": {
      backgroundColor: "transparent",
      color: `${primaryColor} !important`,
    },
    "&:focus": {
      backgroundColor: "transparent",
      color: `${primaryColor} !important`,
    },
    "&:active": {
      backgroundColor: "transparent",
      color: `${primaryColor} !important`,
    },
    "&:clicked": {
      backgroundColor: "transparent",
      color: `${primaryColor} !important`,
    }
  },
  outlineButton: {
    ...buttonOverride,
    backgroundColor: "transparent",
    border: `1px solid ${primaryColor}`,
    color: `${primaryColor} !important`,
    "&:hover": {
      backgroundColor: "transparent",
      color: `${primaryColor} !important`,
    },
    "&:focus": {
      backgroundColor: "transparent",
      color: `${primaryColor} !important`,
    },
    "&:active": {
      backgroundColor: "transparent",
      color: `${primaryColor} !important`,
    },
    "&:clicked": {
      backgroundColor: "transparent",
      color: `${primaryColor} !important`,
    }
  },
  darkButton: {
    ...buttonOverride,
    backgroundColor: primaryColor,
    color: `${white} !important`,
    "&:hover": {
      backgroundColor: primaryColor,
      color: `${white} !important`,
    },
    "&:focus": {
      backgroundColor: primaryColor,
      color: `${white} !important`,
    },
    "&:active": {
      backgroundColor: primaryColor,
      color: `${white} !important`,
    },
    "&:clicked": {
      backgroundColor: primaryColor,
      color: `${white} !important`,
    }
  },
  lightButton: {
    ...buttonOverride,
    backgroundColor: fadedPrimaryColor,
    color: `${primaryColor} !important`,
    "&:hover": {
      backgroundColor: fadedPrimaryColor,
      color: `${primaryColor} !important`,
    },
    "&:focus": {
      backgroundColor: fadedPrimaryColor,
      color: `${primaryColor} !important`,
    },
    "&:active": {
      backgroundColor: fadedPrimaryColor,
      color: `${primaryColor} !important`,
    },
    "&:clicked": {
      backgroundColor: fadedPrimaryColor,
      color: `${primaryColor} !important`,
    }
  },
  deleteButton: {
    ...buttonOverride,
    backgroundColor: dangerColor,
    padding: "10px 20px",
    "&:hover": {
      backgroundColor: dangerColor,
    },
    "&:focus": {
      backgroundColor: dangerColor,
    },
    "&:active": {
      backgroundColor: dangerColor,
    },
    "&:clicked": {
      backgroundColor: dangerColor,
    },
  },
  cancelButton: {
    ...buttonOverride,
    margin: "auto 5px",
    backgroundColor: grayColor,
    padding: "10px 20px",
    "&:hover": {
      backgroundColor: grayColor,
    },
    "&:focus": {
      backgroundColor: grayColor,
    },
    "&:active": {
      backgroundColor: grayColor,
    },
    "&:clicked": {
      backgroundColor: grayColor,
    },
  },
  bigButton: {
    padding: "20px 40px",
  },
  mediumButton: {
    padding: "10px 20px",
  },
  smallButton: {
    padding: "5px 10px",
  },
  invisibleButton: {
    padding: "0",
    margin: "0 auto 3px auto",
    fontWeight: "bold !important"
  },

  ////////////// Link styling overrides //////////////
  linkOverride: {
    ...linkOverride,
  },
  linkDarkText: {
    ...linkOverride,
    color: primaryColor,
    "&:hover": {
      color: primaryColor,
    },
    "&:focus": {
      color: primaryColor,
    },
    "&:active": {
      color: primaryColor,
    },
    "&:clicked": {
      color: primaryColor,
    }
  },
  linkWhiteText: {
    ...linkOverride,
    color: white,
    "&:hover": {
      color: white,
    },
    "&:focus": {
      color: white,
    },
    "&:active": {
      color: white,
    },
    "&:clicked": {
      color: white,
    }
  },

  ////////////// Titles and Headers //////////////
  title: {
    display: "inline-block",
    position: "relative",
    // minHeight: "32px",
    color: white,
    textDecoration: "none",
    fontFamily: "var(--font-brandon-grotesque)",
    fontWeight: 900,
    fontSize: "1.8em",
    transform: "translateX(0%)",
    textAlign: "center",
    width: "100%",
    [theme.breakpoints.down('xl')]: {
      marginTop: "20px !important",
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: "2.1em",
    },
    [theme.breakpoints.down('sm')]: {
      margin: "0 10% 10px 10%",
      width: "80%",
      lineHeight: "1.3em"
    }
  },
  subtitle: {
    fontSize: "0.9em",
    maxWidth: "60vw",
    margin: "0 auto 20px auto",
    color: white,
    zIndex: "12",
    textAlign: "center",
    lineHeight: "1.3",
    [theme.breakpoints.down('md')]: {
      maxWidth: "80vw",
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: "1.0em",
    },
  },
  sectionTitle: {
    display: "inline-block",
    position: "relative",
    marginTop: "260px",
    minHeight: "32px",
    color: primaryColor,
    textDecoration: "none",
    fontFamily: "var(--font-brandon-grotesque)",
    fontWeight: 900,
    fontSize: "1.5em",
    transform: "translateX(0%)",
    textAlign: "center",
    width: "100%",
    [theme.breakpoints.down('xl')]: {
      marginTop: "20px !important",
    },
    [theme.breakpoints.down('md')]: {
      textAlign: "left",
      fontSize: "1.5em",
      margin: "0 -15px 10px 15px",
      maxWidth: "90%",
    },
  },
  sectionSubtitle: {
    fontSize: "0.9em",
    maxWidth: "50vw",
    margin: "0 auto 10px auto",
    zIndex: "12",
    textAlign: "center",
    [theme.breakpoints.down('md')]: {
      maxWidth: "90%",
      textAlign: "left",
      margin: "0 -15px 10px 15px",
      fontSize: "1.1rem",
    },

  },

  ////////////// Forums Index Header //////////////
  headerContainer: {
    height: "20vh !important"
  },
  titleBannerContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
  },

  ////////////// Forums Index FAQ Accordion //////////////
  accordion: {
    boxShadow: 'none',
    borderRadius: '5px !important',
    overflow: 'hidden',
    width: "80vw",
    margin: "auto auto 16px auto",
    '&:last-child': {
      marginBottom: 0,
    },
    '&.Mui-expanded': {
      margin: "auto auto 8px auto",
    },
    '.MuiAccordionSummary-root': {
    },
    [theme.breakpoints.down('md')]: {
      margin: "auto 0 16px 10px",
      width: "95vw",
      '&:last-child': {
        marginBottom: 0,
      },
      '&.Mui-expanded': {
        margin: "auto 0 16px 10px",
      },
    }
  },
  accordionSummary: {
    ...title,
    margin: '0',
    borderBottom: `1px solid ${tertiaryColor}`,
    '&.Mui-expanded': {
      minHeight: 'auto',
    },
    '.MuiAccordionSummary-content': {
      margin: '12px !important',
      width: "100% !important",
      display: 'flex',
      justifyContent: 'space-between',
    },
    h3: {
      fontSize: '1.3em',
    },
    [theme.breakpoints.down('md')]: {
      '.MuiAccordionSummary-content': {
        margin: '12px 0 !important',
        width: "100% !important",
        display: 'flex',
        justifyContent: 'space-between',
      },
    }
  },
  accordionProgress: {
    display: 'block',
    fontSize: '1em',
    whiteSpace: 'nowrap',
    svg: {
      marginLeft: '10px',
      marginRight: '8px',
      verticalAlign: 'middle',
    }
  },
  accordionDetails: {
    width: "95%",
    textAlign: "left",
    margin: "16px 10px",
    [theme.breakpoints.down('md')]: {
      width: "100%",
      margin: "16px 0",
    }
  },
  accordionIcon: {
    fontSize: '2.8em',
    verticalAlign: 'middle',
    margin: "auto 0px",
  },
  accordionTitle: {
    fontWeight: "bold",
  },

  ////////////// Forums Index Grid //////////////
  homepageGridMobileOverride: {
      margin: "0 0 0 0 !important",
      padding: "0 0 0 0 !important",
  },
  card: {
    padding: "10px 0 5px 0",
    display: "flex",
    margin: "10px 0 0 0",
    flexDirection: "column",
    [theme.breakpoints.down('md')]: {
      padding: "0",
      margin: "15px 0",
    },
  },
  cardImage: {
    objectFit: "cover",
    padding: "10px 0",
    maxHeight: "220px",
    minHeight: "215px",
    maxWidth: "80vw",
    position: "relative !important",
    margin: "0",
    [theme.breakpoints.down('md')]: {
      minHeight: "280px",
      maxHeight: "280px",
      maxWidth: "100%",
    },
    [theme.breakpoints.down('sm')]: {
      minHeight: "250px",
      maxHeight: "250px",
      padding: "10px 0",
    },
    [theme.breakpoints.up('lg')]: {
      minHeight: "270px",
      maxHeight: "270px",
    },
    [theme.breakpoints.up('xl')]: {
      minHeight: "320px",
      maxHeight: "320px",
    }
  },
  cardTitle: {
    margin: "10px 0 0 0",
    fontWeight: "bold",
    fontSize: "1.3em",
    color: primaryColor,
    maxWidth: "60vw",
    [theme.breakpoints.down('md')]: {
      maxWidth: "90vw",
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: "1.5em",
    }
  },
  cardText: {
    margin: "5px 0 0 0",
    fontSize: "0.9em",
    maxWidth: "60vw",
    minHeight: "90px",
    lineHeight: "1.5em",
    [theme.breakpoints.down('lg')]: {
      minHeight: "90px",
    },
    [theme.breakpoints.down('md')]: {
      minHeight: "auto",
      maxWidth: "90vw",
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: "1em",
    }
  },
  gridContainer: {
    margin: "20px auto",
  },
  gridContainerMarginAdjust: {
    [theme.breakpoints.up('md')]: {
      transform: "translateX(-15px)",
    },
    "& > div:nth-child(2n)": {
      [theme.breakpoints.up('md')]: {
        transform: "translateX(30px)",
      }
    }
  },
  gridItem: {
    padding: "0 10px",
    margin: "15px 0",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  gridItemReducePadding: {
    padding: "0",
  },
  forumsButton: {
    margin: "20px auto 20px 0",
    padding: "15px 20px",
    fontSize: "1em",
  },

  ////////////// Forums Community Page Banner //////////////
  forumsBannerContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    maxHeight: "215px",
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column",
      justifyContent: "flex-start",
      margin: "0 auto !important",
      width: "100vw",
    }
  },
  forumsBannerTitle: {
    fontSize: "1.8em",
    fontWeight: "bold",
    margin: "0 0 10px 0",
    [theme.breakpoints.down('sm')]: {
      fontSize: "2.5em",
      width: "100%",
    },
  },
  forumsBannerDescription: {
    fontSize: "0.9em",
    lineHeight: "1.5em",
  },
  forumsBannerLeft: {
    color: primaryColor,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    minWidth: "50%",
    maxWidth: "50%",
    padding: "20px",
    margin: "0 35px 0 -35px",
    maxHeight: "210px",
    [theme.breakpoints.down("sm")]: {
      minWidth: "100%",
      maxWidth: "100vw !important",
      padding: "20px 0 !important",
      margin: "auto !important",
    }
  },
  forumsBannerRight: {
    width: "100%",
    height: "210px",
    position: "relative",
    [theme.breakpoints.up("lg")]: {
      marginLeft: "10%"
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    }
  },
  bannerRightImageBg: {
    position: "absolute",
    objectFit: "contain",
    top: "0",
    minWidth: "340px",
    maxWidth: "340px",
    zIndex: 2
  },
  bannerRightImage: {
    objectFit: "contain",
    margin: "auto",
    position: "absolute",
    top: "0",
    left: "0",
    minWidth: "300px",
    maxWidth: "300px", 
    minHeight: "200px",
    maxHeight: "200px",   
    zIndex: 3,
  },
  bannerRightImageFrame: {
    // clipPath: "polygon(0px 0px, 160px 30px, 260px 0px, 300px 155px, 175px 127px, 50px 160px)", // based on width 300, height 165
    clipPath: "polygon(0% 0%, 53.33% 18.18%, 86.67% 0%, 100% 93.94%, 58.33% 76.97%, 16.67% 96.97%)",
    position: "relative",
    height: "100%",
    maxHeight: "165px",
    width: "300px",
    overflow: "hidden",
    zIndex: 4,
    aspectRatio: "1 / 1",
    top: "27px",
    left: "32px",
  },
  bannerRightImageDecor: {
    position: "absolute",
    objectFit: "contain",
    top: "0",
    left: "0",
    minWidth: "370px",
    maxWidth: "370px",
    minHeight: "220px",
    maxHeight: "220px",
    zIndex: 5
  },
  forumsBanner: {
    backgroundColor: bannerBlue,
    padding: "10px 20px",
    maxHeight: "240px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px 0 10px 0",
    }
  },
  bannerLower: {
    display: "flex",
    borderTop: `1px solid ${lightGrey}`,
    paddingTop: "20px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      minWidth: "100vw !important",
      maxWidth: "100vw !important",
      margin: "0 !important",
    }
  },
  bannerSubCategoriesContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    padding: "10px 20px 10px 35px",
    margin: "auto",
    maxWidth: "80% !important",
    overflow: "scroll",
    mask: `linear-gradient(90deg, transparent 2%, ${bannerBlue} 6%, ${bannerBlue} 88%, transparent 98%)`,
    [theme.breakpoints.down("sm")]: {
      padding: "10px 0 10px 0",
      width: "100vw",
      maxWidth: "100vw !important",
      margin: "0 !important",
      mask: "none"
    }
  },
  bannerSubCategoryLink: {
    textDecoration: "none",
    "&:hover": {
      color: primaryColor,
    }
  },
  bannerSubCategory: {
    fontSize: "1em",
    textAlign: "center",
    backgroundColor: "white",
    padding: "10px",
    margin: "10px",
    borderRadius: "50px !important",
    border: `1px solid ${lightGrey}`,
    "&:last-child": {
      marginRight: "50px"
    },
    "&:hover": {
      color: primaryColor,
    },
    [theme.breakpoints.down("sm")]: {
      margin: "10px 4px",
      "&:last-child": {
        marginRight: "8px"
      },
      "&:first-child": {
        marginLeft: "8px"
      }
    }
  },
  bannerSubCategoryText: {
    textAlign: "center",
    margin: "auto",
    fontWeight: "bold",
    "&:hover": {
      color: primaryColor,
    },
  },
  bannerSubCategoryTitle: {
    [theme.breakpoints.down("sm")]: {
      margin: "0 0 0 15px !important",
      textAlign: "left",
    }
  },

  ////////////// Forums Banner Search Bar //////////////
  formControl: {
    borderRadius: "50px",
    maxWidth: "90% !important",
    margin: "auto 10px !important",
    padding: "10px !important",
  },
  forumsSearch: {
    backgroundColor: "white",
    borderRadius: "50px",
    display: "flex",
    justifyContent: "space-between",
    margin: "20px 0 0 0",
    minWidth: "50%",
    zIndex: "12",
    [theme.breakpoints.down("md")]: {
      minWidth: "100%",
    }
  },
  searchIcon: {
    margin: "0 20px 3px 5px",
    color: primaryColor,
  },
  searchIconLink: {
    margin: "auto",
    color: primaryColor,
    border: 0,
    background: "none",
    cursor: "pointer",
    boxShadow: "none !important",
  },

  searchResultsContainer: {
    [theme.breakpoints.up("sm")]: {
      minWidth: "70%",
    }
  }, 
  
  ////////////// Topics //////////////
  topicsContainer: {
    display: "flex",
    flexDirection: "column",
    margin: "0 0 0 -10px",
    width: "max(100%, 750px)",
    [theme.breakpoints.down("lg")]: {
      width: "min(75%, 750px)",
    },
    [theme.breakpoints.down("md")]: {
      margin: "auto",
      minWidth: "100vw !important",
      maxWidth: "100vw !important",
    }
  },
  topicCard: {
    border: `1px solid ${lightGrey}`,
    borderRadius: "20px",
    margin: "10px",
    padding: "10px",
    [theme.breakpoints.down("md")]: {
      margin: "10px 0",
      maxWidth: "90vw !important",
      minWidth: "90vw !important",
    }
  },
  topicLink: {
    ...linkOverride,
  },
  topicUserHeader: {
    display: "flex",
    justifyContent: "space-between",
  },
  topicUserInfo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "5px 3px",
    padding: "5px"
  },
  topicTitle: {
    fontSize: "1.2em",
    fontWeight: "bold",
    color: primaryColor,
    margin: "10px 0 10px 10px !important",
    "&:hover": {
      textDecoration: "none",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "310px !important",
    }
  },
  topicDescriptionClipped: {
    fontSize: "1.1em",
    margin: "0 10px",
    maxHeight: "3em",
    maxWidth: "700px !important",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: "black",
    "&:hover": {
      textDecoration: "none",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "300px !important",
      maxHeight: "7em",
      textWrap: "wrap"
    }
  },
  topicDescription: {
    fontSize: "1.1em",
    margin: "0 10px",
  },
  topicTagsContainer: {
    display: "flex",
    margin: "10px 10px 5px 10px",
    flexWrap: "wrap",
  },
  topicTag: {
    margin: "0 5px",
    padding: "3px 10px",
    borderRadius: "50px !important",
    backgroundColor: bannerBlue,
    color: `${primaryColor} !important`,
    fontWeight: "bold"
  },
  addTag: {
    margin: "5px",
    padding: "10px 15px",
    borderRadius: "50px !important",
    border: `1px solid ${lightGrey}`,
    boxShadow: "none",
    backgroundColor: white,
    color: `black !important`,
    fontWeight: "normal",
    "&:hover": {
      backgroundColor: primaryColor,
      color: `${white} !important`,
      boxShadow: "none",
    },
    "&:focus": {
      backgroundColor: white,
      color: `black !important`,
      boxShadow: "none",
    },
  },
  addTagSelected: {
    margin: "5px",
    padding: "10px 10px 10px 15px",
    borderRadius: "50px !important",
    boxShadow: "none",
    backgroundColor: primaryColor,
    color: `${white} !important`,
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: primaryColor,
      color: `${white} !important`,
      boxShadow: "none",
    },
    "&:focus": {
      backgroundColor: primaryColor,
      color: `${white} !important`,
      boxShadow: "none",
    },
  },
  embeddedClose: {
    margin: "auto 0 auto 5px",
    pointerEvents: "none",
  },
  topicLikesReplies: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    padding: "10px"
  },
  topicsHeader: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0 20px",
    margin: "0 10px",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      margin: "0",
      padding: "0"
    }
  },
  topicsTitle: {
    fontSize: "1.7em",
    fontWeight: "bold",
    margin: "20px 0 20px -20px",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.7em",
      margin: "20px 0",
    },

  },
  topicButtons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "0 -25px 0 0"
  },
  topicFilter: {
    backgroundColor: "white",
    borderRadius: "50px !important",
    margin: "0 10px",
    maxHeight: "40px",
    padding: "12px 3px",
    "&:focus": {
      backgroundColor: "white",
      border: `1px solid ${lightGrey}`,
    },
    "&:selected": {
      backgroundColor: "white",
      border: `1px solid ${lightGrey}`,
    },
    [theme.breakpoints.down("md")]: {
      margin: "0 5px 10px 5px",
    }
  },
  topicFilterText: {
    color: primaryColor,
    margin: "auto",
    "&:hover": {
      color: primaryColor
    },
    padding: "2px 10px",
  },
  topicFilterIcon: {
    color: primaryColor,
    "&:hover": {
      color: primaryColor
    },
    padding: "0 0 0 10px"
  },
  newTopicButton: {
    margin: "0 10px",
    padding: "12px 20px !important",
    [theme.breakpoints.down("md")]: {
      margin: "0 0 10px 0",
    }
  },
  topicCardFooter: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px"
  },
  upvoteButton: {
    padding: "0",
    margin: "0",
  },
  forumContent: {
    margin: "2px 0 0 0",
    [theme.breakpoints.down("md")]: {
      minWidth: "90vw !important",
      maxWidth: "90vw !important",
    }
  },

  ////////////// Topic icons //////////////
  icon: {
    margin: "0 8px",
    color: primaryColor
  },
  loadingIcon: {
    margin: "0 8px",
    color: primaryColor,
    width: "25px",
    height: "25px",
  },
  optionsIcon: {
    margin: "10px",
    padding: "10px"
  },
  count: {
    margin: "0 8px",
  },
  postTime: {
    margin: "3px 5px 0 5px",
    fontSize: "0.8em"
  },

  ////////////// Topic Card User Profile //////////////
  userProfilePic: {
    borderRadius: "50%",
  },
  userName: {
    color: primaryColor,
    fontWeight: "bold",
    margin: "0 10px",
    fontSize: "1.1em",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "200px !important",
      overflow: "hidden",
      textOverflow: "ellipsis",
    }
  },
  userStatus: {
    fontSize: "0.3em",
    margin: "3px 5px 0 0",
    color: primaryColor
  },

  ////////////// Forum Categories Section //////////////
  forumCategoryContainer: {
    width: "100% !important",
    display: "flex",
    justifyContent: "space-between",
  },
  forumCategory: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    textAlign: "center",
  },
  forumCategoryImage: {
    margin: "auto",
    borderRadius: "50%",
    position: "relative",
    width: "180px",
    height: "180px",
    maxWidth: "180px",
    maxHeight: "180px",
    [theme.breakpoints.down("lg")]: {
      width: "180px",
      height: "180px",
      maxWidth: "180px",
      maxHeight: "180px",
    },
    [theme.breakpoints.down("md")]: {
      width: "180px",
      height: "180px",
      maxWidth: "180px",
      maxHeight: "180px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "200px",
      height: "200px",
      maxWidth: "200px",
      maxHeight: "200px",
    }
  },
  forumCategoryName: {
    fontSize: "1.2em",
    fontWeight: "bold",
    color: primaryColor,
    textAlign: "center",
    textDecoration: "none",
  },

  ////////////// Forum Side Panel Section //////////////
  forumSidePanel: {
    maxWidth: "260px",
    [theme.breakpoints.up("lg")]: {
      maxWidth: "280px"
    },
    [theme.breakpoints.up("xl")]: {
      maxWidth: "350px"
    },
    [theme.breakpoints.down("md")]: {
      maxWidth: "100%",
      margin: "auto"
    }
  },
  forumWithSidePanel: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "center",
      margin: "0"
    }
  },
  forumSidePanelPostTitle: {
    fontWeight: "bold",
    fontSize: "1em",
    margin: "15px 0 5px 0",
    color: primaryColor,
  },
  forumSidePanelPostText: {
    paddingBottom: "15px",
    borderBottom: `1px solid ${fadedPrimaryColor}`,

  },
  forumSidePanelBox: {
    border: `1px solid ${lightGrey}`,
    borderRadius: "20px",
    margin: "10px",
    padding: "20px",
    [theme.breakpoints.down("md")]: {
      margin: "10px auto"
    }
  },
  forumSidePanelTitle: {
    fontWeight: "bold",
    fontSize: "1.2em",
    margin: "10px 0",
    lineHeight: "1.3em",
  },
  forumSidePanelText: {
    fontSize: "0.9em",
    lineHeight: "1.4em",
  },
  forumSidePanelTags: {
    display: "flex",
    flexWrap: "wrap"
  },
  forumSidePanelTagText: {
    ...linkOverride,
    color: primaryColor,
    padding: "2px 10px",
    margin: "5px",
    border: `1px solid ${primaryColor}`,
    borderRadius: "50px",
    fontSize: "0.8em",
    fontWeight: "bold",
    textAlign: "center",
  },
  forumSidePanelApprentice: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    margin: "10px",
    border: "none",
    backgroundColor: "transparent",
    boxShadow: "none",
    padding: "3px 0",
    "&:hover": {
      border: "none",
      backgroundColor: "transparent",
      boxShadow: "none",
    },
    "&:focus": {
      border: "none",
      backgroundColor: "transparent",
      boxShadow: "none",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      width: "100vw !important",
      margin: "0",
    }
  },
  forumSidePanelApprenticeImg: {
    borderRadius: "50%",
    [theme.breakpoints.down("sm")]: {
      margin: "5px"
    }
  },
  forumSidePanelApprenticeInfo: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    overflow: "hidden",
    marginLeft: "10px",
    lineHeight: "1.2em",
    height: "min-content"
  },
  forumSidePanelApprenticeName: {
    fontSize: "1.1em",
    fontWeight: "bold",
    color: primaryColor,
    margin: "0",
  },
  forumSidePanelApprenticeTitle: {
    fontSize: "0.95em",
    margin: "5px 0",
    width: "100%",
    overflow: "hidden",
    textWrap: "wrap",
    textAlign: "left",
    color: "black"
  },
  apprentices: {
    display: "flex",
    padding: "0 0 10px 0",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "row",
    }
  },
  apprenticeContainer: {
    padding: "20px !important"
  },
  apprenticesContainer: {
    margin: "10px auto",
  },
  apprenticesOuterContainer: {
    marginLeft: "-10px",
    [theme.breakpoints.down("md")]: {
      marginLeft: "0",
    }
  },

  ////////////// Breadcrumbs //////////////
  breadcrumbs: {
    fontFamily: "var(--font-brandon-grotesque)",
    fontSize: "0.9em",
    margin: "30px 0 20px 0",
    [theme.breakpoints.down("md")]: {
      lineHeight: "3.8em",
      margin: "10px",
    }
  },
  breadcrumbsIcon: {
    height: "0.8em",
    padding: "0 5px 0 0",
  },

  ////////////// Options Menu //////////////
  optionsMenu: {
    padding: "10px 20px",
  },
  optionsMenuItem: {
    fontSize: "16px",
    fontWeight: "bold",
    "&:hover": {
      color: primaryColor,
    },
    display: "flex",
    alignItems: "center",
  },
  optionsMenuItemText: {
    margin: "0 5px",
    fontSize: "0.9em",
  },
  optionsIcon: {
    color: primaryColor,
    "&:hover": {
      color: primaryColor,
    },
  },

  ////////////// Modal //////////////
  modalRoot: {
    fontFamily: "var(--font-brandon-grotesque)",
    overflow: "scroll !important",
    display: "block",
  },
  modalCloseButtonHeader: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
  },
  modalCloseButton: {
    backgroundColor: closeButtonGrey,
    borderRadius: "50px !important",
    padding: "8px",
    boxShadow: "none",
    color: "black !important",
    "&:hover": {
      backgroundColor: lighterGrey,
      boxShadow: "none",
    },
    "&:focus": {
      backgroundColor: closeButtonGrey,
      boxShadow: "none",
    },
    "&:active": {
      backgroundColor: lighterGrey,
      boxShadow: "none",
    },
    "&:clicked": {
      backgroundColor: closeButtonGrey,
      boxShadow: "none",
    },
  },
  modal: {
    backgroundColor: "white",
    borderRadius: "10px",
    align: "center",
    verticalAlign: "middle",
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: "20px",
    display: "block",
    width: "inherit",
    [theme.breakpoints.up('lg')]: {
    },
    [theme.breakpoints.down('sm')]: {
      left: "10%",
      width: "80%",
    },
  },
  modalContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  modalTitle: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "1.5em",
    margin: "20px auto",
  },
  modalText: {
    textAlign: "center",
    fontSize: "1em",
    marginBottom: "30px",
  },
  modalIconsContainer: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    width: "80%",
    marginBottom: "30px",
  },
  modalIcon: {
    color: primaryColor,
    margin: "0 !important"
  },
  modalTagContainer: {
    margin: "5px 10px",
    display: "flex",
    flexWrap: "wrap",
  },
  modalReportTag: {
    backgroundColor: "white",
    border: `1px solid ${primaryColor}`,
    borderRadius: "50px !important",
    padding: "8px 12px",
    boxShadow: "none",
    color: `${primaryColor} !important`,
    margin: "3px",
    "&:hover": {
      backgroundColor: lighterGrey,
      boxShadow: "none",
    },
    "&:focus": {
      backgroundColor: lighterGrey,
      boxShadow: "none",
    },
  },
  drawer: {
    padding: "10px",
    '&.MuiDrawer-paper': {
      borderRadius: "30px 30px 0 0 !important",
      margin: "0 5px !important",
    }
  },

  ////////////// No Topics Section //////////////
  noTopicsBox: {
    border: `1px solid ${lightGrey}`,
    borderRadius: "20px",
    padding: "40px",
    margin: "10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    maxHeight: "300px",
    [theme.breakpoints.down('sm')]: {
      maxHeight: "400px",
      padding: "25px",
      width: "100%",
      margin: "0 0 0 5px",
    },
  },
  noTopicsTitle: {
    fontSize: "1.5em",
    fontWeight: "bold",
    textAlign: "center",
  },
  noTopicsText: {
    textAlign: "center",
    fontSize: "1em",
    marginBottom: "30px",
  },
  noTopicsContainer: {
    maxWidth: "100%",
    display: "flex",
    flexDirection: "column",
    margin: "0 0 0 -10px",
    [theme.breakpoints.up('sm')]: {
      margin: "0",
    }
  },

  ////////////// Loading Spinner //////////////
  spinnerContainer: {
    width: "100%",
    margin: "100px auto !important",
    display: "flex",
    justifyContent: "center",
  },
  spinner: {
    margin: "0 auto",
    animation: `${spin} 1.2s linear infinite`,
  },

  ////////////// Replies section //////////////
  replies: {
    position: "relative",
    marginLeft: "30px",
    [theme.breakpoints.down('sm')]: {
      margin: "0",
      minWidth: "85vw",
    }
  },
  repliesButton: {
    fontSize: "18px",
    border: `1px solid ${primaryColor}`,
    [theme.breakpoints.down('sm')]: {
      marginLeft: "10px"
    }
  },
  repliesButtonIcon: {
    height: "1.2em !important",
    width: "1.2em !important",
    margin: "0",
    padding: "0 3px"
  },
  repliesTitle: {
    fontSize: "1.2em",
    fontWeight: "bold",
    margin: "20px 0 30px 10px",
  },

  ////////////// Thread closed //////////////
  threadClosed: {
    display: "flex",
    alignItems: "center",
    width: "95%",
    backgroundColor: fadedPrimaryColor,
    margin: "20px auto",
    padding: "10px",
    borderRadius: "10px",
  },
  threadClosedIcon: {
    width: "20px",
    height: "20px",
    margin: "0 20px 0 10px",
  },
  threadClosedText: {
    margin: 'auto 5px auto 0'
  },
  threadClosedButtons: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },

  ////////////// Add post/text editor //////////////
  addPostModal: {
    backgroundColor: "white",
    borderRadius: "10px",
    align: "center",
    verticalAlign: "middle",
    marginTop: "-40px"
  },
  topicTagButton: {
    ...buttonOverride,
    backgroundColor: `${white}`,
    border: `2px solid ${primaryColor}`,
    margin: "3px",
    ":hover": {
      backgroundColor: `${bannerBlue} !important`,
    },
    ":selected": {
      backgroundColor: `${bannerBlue} !important`,
    }
  },
  formLabel: {
    fontWeight: "bold",
    margin: "10px"
  },
  editor: {
    color: primaryColor,
    fontFamily: "var(--font-brandon-grotesque) !important",
  },
  editorButtons: {
    display: "flex",
    justifyContent: "center",
    margin: "10px",
  },
  submitButton: {
    margin: "auto 5px",
  },
  editedText: {
    fontSize: "0.8em",
    color: grayColor,
    margin: "0 0 0 30px",
    fontStyle: "italic",
  },
  apprenticeText: {
    fontSize: "0.9em",
    backgroundColor: fadedPrimaryColor,
    borderRadius: "50px",
    padding: "0 10px !important",
    border: `1px solid ${primaryColor}`,
    fontWeight: "bold",
    margin: "auto 5px",
    color: primaryColor,
  },
  apprenticeHighlightBorder: {
    border: `3px solid ${fadedPrimaryColor}`,
  },

  ////////////// Search page //////////////
  searchBanner: {
    backgroundColor: primaryColor,
    color: "white",
    padding: "50px 0",
    marginBottom: "50px",
    height: "30vh",
    [theme.breakpoints.down('sm')]: {
      marginBottom: "0",
    }
  },
  searchBannerContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    margin: "auto"
  },
  searchResults: {
    textAlign: "center !important",
  },

  ////////////// Login barrier //////////////
  loginBarrier: {
    position: "absolute",
    // paddingTop: "100px",
    height: "50%",
    paddingBottom: "30px",
    bottom: "0",
    width: "100%",
    minHeight: "220px",
    maxHeight: "400px",
    fontFamily: "var(--font-brandon-grotesque)",
    background: "linear-gradient(180deg, transparent 0%, rgba(255,255,255, 0.7) 15%, white 50%)",
    display: "flex",
    alignItems: "end",
    justifyContent: "center",
  },


  ////////////// Report modal //////////////
  reportModalContent: {
    padding: "5px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  reportModalTitle: {
    textAlign: "left",
    fontWeight: "bold",
    fontSize: "1.3em",
    margin: "0 0 10px 0",
  },
  reportModalText: {
    textAlign: "left",
    fontSize: "1em",
  },
  modalReportTagHeader: {
    backgroundColor: primaryColor,
    color: white,
    borderRadius: "50px",
    padding: "5px 15px",
    textAlign: "center",
    width: "fit-content",
    margin: "3px 0"
  },
  reportModalTextEditor: {
    margin: "10px 0",
    width: "100%"
  },
  reportModalSubmitButton: {
    alignSelf: "flex-end",
    marginTop: "20px",
  },

  ////////////// Quick view //////////////
  quickViewHeader: {
    display: "flex",
    justifyContent: "start",
    alignItems: "flex-start",
    width: "100%",
    margin: "0 0 10px 0",
  },
  quickViewContent: {
    padding: "0 0 10px 0",
    alignItems: "center",
    justifyContent: "flex-start",
    marginTop: "-30px",
    marginLeft: "-20px",
    width: "100% !important",
    [theme.breakpoints.down('sm')]: {
      marginBottom: "10px"
    }
  },
  quickViewImage: {
    borderRadius: "50%",
    padding: "0",
    margin: "0 0 10px 20px",
  },
  quickViewContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    width: "100%",
  },
  quickViewAccountData: {
    marginTop: "5px"
  },
  quickViewIcons: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    margin: "0",
    width: "100%",
    margin: "0 0 0 20px",
    [theme.breakpoints.down('md')]: {
      margin: "0 0 0 40px",
    },

  },
  quickViewIcon: {
    margin: "3px 5px 5px 0",
  },
  quickViewDataText: {
    fontWeight: "bold",
    fontSize: "0.85em",
    margin: "auto 5px auto 0",
  },
  quickViewApprenticeName: {
    fontWeight: "bold",
    fontSize: "1.2em",
    margin: "0 0 0 20px",
    [theme.breakpoints.up('lg')]: {
      fontSize: "1.5em",
    }
  },
  quickViewApprenticeTitle: {
    fontSize: "0.9em",
    margin: "0 0 0 20px",
  },
  quickViewCommunitiesContainer: {
    width: "100%",
    marginLeft: "20px",
  },
  quickViewCommunities: {
    display: "flex",
    marginBottom: "10px",
  },
  apprenticePageCommunities: {
    display: "flex",
    marginBottom: "10px",
    minHeight: "2.9em",
    [theme.breakpoints.up('sm')]: {
      justifyContent: "center",
    }
  },
  apprenticePagePosition: {
    color: "black",
    fontSize: "0.9em",
    minHeight: "1em",
    maxHeight: "3em",
    padding: "5px 0",
    textWrap: "wrap",
    overflow: "hidden",
    [theme.breakpoints.down('sm')]: {
      textAlign: "left",
    }
  },
  quickViewCommunitiesTitle: {
    fontSize: "1em",
    fontWeight: "bold",
    margin: "0 0 0 10px",
    [theme.breakpoints.down('md')]: {
      margin: "10px 0 0 10px",
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: "1.2em",
    }
  },
  quickViewCommunityLink: {
    textDecoration: "none",
    backgroundColor: fadedPrimaryColor,
    borderRadius: "50px",
    padding: "5px 10px",
    margin: "5px",
    color: primaryColor,
    "&:hover": {
      textDecoration: "none",
      color: primaryColor,
      backgroundColor: fadedPrimaryColor,
    },
    "&:focus": {
      textDecoration: "none",
      color: primaryColor,
      backgroundColor: fadedPrimaryColor,
    },
    [theme.breakpoints.up('md')]: {
      margin: "5px 5px 5px 10px",
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: "1.2em",
    }
  },
  quickViewCommunityLinkText: {
    margin: "auto",
    fontWeight: "bold",
    fontSize: "0.9em"
  },
  quickViewButton: {
    ...linkOverride,
    fontWeight: "bold",
    padding: "10px 20px !important",
    [theme.breakpoints.up('md')]: {
      margin: "15px 5px 5px 5px",
      padding: "10px 20px",
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: "1em",
    },
    [theme.breakpoints.down('md')]: {
      textAlign: "center",
    }
  },
  quickViewDataItem: {
    display: "flex",
    alignItems: "center",
    margin: "auto 0",
    padding: "0",
    [theme.breakpoints.down('md')]: {
      flexDirection: "column",
      alignItems: "flex-start",
    }
  },

  ////////////// Apprentices //////////////
  apprenticeButton: {
    backgroundColor: "transparent",
    color: "black",
    border: `1px solid rgba(0, 0, 0, 0.2)`,
    borderRadius: "50px",
    boxShadow: "none",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    "&:hover": {
      backgroundColor: "transparent",
      boxShadow: "none",
    },
    "&:focus": {
      backgroundColor: "transparent",
      boxShadow: "none",
    },
    "&:active": {
      backgroundColor: "transparent",
      boxShadow: "none",
    },
    "&:clicked": {
      backgroundColor: "transparent",
      boxShadow: "none",
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: "row",
      justifyContent: "space-around",
      gap: "0",
      margin: "0",
      padding: "10px 0"
    }
  },
  smallerGridGap: {
    [theme.breakpoints.down('sm')]: {
      margin: "8px 0 !important",
    }
  },
  meetApprenticeTitle: {
    fontSize: "1.5em",
    margin: "0"
  },
  meetApprenticeImg: {
    borderRadius: "50%",
    [theme.breakpoints.down('sm')]: {
      maxWidth: "30vw",
      maxHeight: "30vw",
      margin: "10px"
    }
  },
  meetApprenticeContent: {
    [theme.breakpoints.down('sm')]: {
      maxWidth: "50vw",
      maxHeight: "50vw",
      margin: "10px 5px",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      width: "100%",
    }
  },
  apprenticeData: {
    color: "black",
    margin: "auto 10px auto 0",
    fontSize: "0.8em",
  },
  apprenticeIcons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "0",
    width: "100%",
    [theme.breakpoints.down('sm')]: {
      justifyContent: "flex-start",
    }
  },
  apprenticeCommunity: {
    backgroundColor: fadedPrimaryColor,
    borderRadius: "50px",
    padding: "10px 20px",
    margin: "5px",
    color: primaryColor,
    "&:hover": {
      textDecoration: "none",
      color: primaryColor,
      backgroundColor: fadedPrimaryColor,
    },
    "&:focus": {
      textDecoration: "none",
      color: primaryColor,
      backgroundColor: fadedPrimaryColor,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: "0.8em",
    }
  },
  apprenticesTitle: {
    [theme.breakpoints.down('lg')]: {
      fontSize: "2.5em",
      margin: "0"
    }
  },
  apprenticesSubtitle: {
    [theme.breakpoints.down('lg')]: {
      fontSize: "1.1em",
      margin: "0 auto 5px auto"
    },
    [theme.breakpoints.down('sm')]: {
      margin: "0 auto 10px auto"
    }
  },
  apprenticesSearchContainer: {
    [theme.breakpoints.down('lg')]: {
      margin: "0 auto",
      "& > *": {
        margin: "3px auto 0 auto",
      }
    }
  },
  ////// Notifications
  notificationModal: {
    maxHeight: "90vh",
  },
  todayNotificationsIfModal: {
    maxHeight: "30vh",
  },
  notificationsIfModal: {
    maxHeight: "60vh",
  },
  notifications: {
    paddingTop: "30px",
    overflow: "scroll"
  },
  markAsReadText: {
    alignSelf: "flex-end",
    margin: "-2em 0",
    color: `${primaryColor} !important`,
    padding: "10px",
    fontWeight: "bold",
    backgroundColor: "transparent",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "transparent",
      boxShadow: "none",
    },
    "&:focus": {
      backgroundColor: "transparent",
      boxShadow: "none",
    },

  },
  notificationCategoryHeader: {
    width: "100%",
    borderBottom: "1px solid " + lightGrey,
    margin: "10px 0",
  },
  notificationCategory: {
    fontWeight: "bold",
  },
  todayNotificationContainer: {
    width: "100%",
    "& > div:first-of-type": {
      backgroundColor: fadedPrimaryColor,
    }
  },
  notificationContainer: {
    width: "100%",
  },
  notification: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "0 0 5px 0",
    padding: "10px",
    borderRadius: "20px",
  },
  notificationContent: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  notificationIcon: {
    maxWidth: "60px",
    maxHeight: "60px",
    objectFit: "contain !important",
    position: "relative !important",
    [theme.breakpoints.down('sm')]: {
      maxWidth: "40px",
      maxHeight: "40px",
      minWidth: "30px",
      minHeight: "30px",
    }
  },
  notificationIconContainer: {
    margin: "auto 10px auto 0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: fadedPrimaryColor,
    borderRadius: "50px",
    width: "60px",
    height: "60px",
    padding: "12px",
    [theme.breakpoints.down('sm')]: {
      width: "40px",
      height: "40px",
      padding: "8px",
    }
  },
  notificationTextContainer: {
    display: "flex",
    flexDirection: "column",
    margin: "auto 10px",
    padding: "10px 0",
  },
  notificationTitle: {
    fontWeight: "bold",
    color: primaryColor,
    lineHeight: "1.0em",
  },
  notificationDescription: {
    lineHeight: "1.0em",
  },
  notificationTimeAgo: {
    color: grayColor,
  },
  notificationsLink: {
    backgroundColor: white,
    width: "100%",
    position: "absolute",
    bottom: "10px",
    textAlign: "center",
    padding: "5px 0"
  },


////////////// Login modal //////////////
 loginModalContainer: {
   display: "flex",
   flexDirection: "column",
   alignItems: "center",
   justifyContent: "center",
   padding: "0 0 20px 0",
 },
 loginModalFormContainer: {
  margin: "0 20px",
 },
  loginModalTitle: {
    fontSize: "1.7em",
    fontWeight: "bold",
  },
  modalSubtitle: {
    marginTop: "10px",
  },
  loginLabel: {
    fontSize: "1em",
    fontWeight: "bold",
    color: "black",
    margin: "0 0 5px 0",
  },
  loginInput: {
    width: "100%",
  },
  loginModalBody: {
    width: "100%",
  },
  loginModalFooter: {
    textAlign: "center",
  },
  loginModalHeader: {
    margin: "-20px 0 20px 0",
    width: "100%",
    padding: "0 20px"
  },
  forumSidePanelButtons: {
    display: "flex",
    justifyContent: "space-around",
    width: "100%",
  },
  signUpButton: {
    fontSize: "0.9em",
    padding: "15px 30px",
  },
  mobileTextInput: {
    fontFamily: "var(--font-brandon-grotesque)",
    fontSize: "1em",
    border: `1px solid ${lightGrey}`,
    borderRadius: "5px",
    width: "100%",
    margin: "5px 0",
    padding: "15px 10px",
    "&:focus": {
      outline: "none",
      border: `1px solid ${lightGrey}`,
    },
    "&::placeholder": {
      color: grayColor,
    }
  }
});

export default forumsStyle;

